import { useFormik } from 'formik';
import Big from 'big.js';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  addGoal,
  getInvestorGoals,
  onGetAccountSetup,
  onPostUserAnswers,
  onSubmitDocuments,
  onPostUserAnswersRiskProfile,
  addGoalPut,
  goalIdAction,
  goalFlowIdAction,
  onPostAddress,
  onPostAddressV1,
  onGetAllUserQuestionAnswers,
  onGetUserQuestionAnswers,
  goalFlowPathAction
} from 'redux/actions';
import { Compound } from './Compound';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { VPSGoal, VPSGoalFunc } from 'validate/VPSGoal';
import { OccupationalGoal } from 'validate/OccupationalGoal';
import DynamicQuestionAnswers from './RiskProfile/DynamicQuestionAnswersLatest';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import ChooseInvestment from './ChooseInvestment/ChooseInvestmentLatest';
import PlannedSaving from './PlannedSaving';
import ProgressBarChart from './AssestAlocation/Component/ProgressBarChart';
import './wizard.css';
import {
  addGoalBodyAction,
  onGetCashFundSummary,
  onGetPersonalisedRiskCategory,
  onGetPersonalisedScore
} from 'redux/reducers/sharedStateData';
import {
  addressFormSchema,
  addressInitialValue,
  mailingAddressFormSchema,
  mailingAddressInitialValue,
  permanentAddressFormSchema,
  permanentAddressInitialValue
} from './AccountSetup/validation';
import ConfirmationRiskProfileScreen from './ConfirmationRiskProfile';
import { CnicValidation } from 'validate/cnicValidation';
import { completeAccount } from './enumFIle';
import { useHistory } from 'react-router-dom';
import AssetAllocation from './AssestAlocation';
import ReviewInfo from './ReviewInfo/ReviewInfo';
import PayNow from 'Latest/PayNow';
import TermCondition from './Term&Condition/TermCondition';
import AccountSetupForm from 'jsx/containers/V2/AccountSetupFormLatest';
import AccountSetup from './AccountSetup/index'
import RiskProfileConfirmation from './RiskProfileConfirmation';
import CashFundAssetAllocation from './CashFundAssetAllocation';
import { WizardStepsEnum } from './Constants';
import CashFundChooseInvestment from './ChooseInvestment/CashFundChooseInvestment';
import { CASH_FUND_ID, GoalNameEnum, PendingQuestionAnswerCategoryEnum, QuestionAnswerCategoryEnum, RETIREMENT_ID, mixPannelTrackerEventName } from '../../../constants';
import GenInvestGraph from 'Latest/Goal/InvestmentCalculator/genInvestGraph';
import CashFundInvestGraph from 'Latest/Goal/InvestmentCalculator/cashfundInvestGraph';
import UpdateRiskProfile from '../RiskProfile/UpdateRiskProfile/UpdateRiskProfile';
import ThirdPartyMahaana from './ThirdPartyMahaana';
import CashfundConfirmationProfile from './ConfirmationRiskProfile/CashfundConfirmationProfileScreen';
import UpdateCashFundProfile from '../RiskProfile/UpdateRiskProfile/UpdateCashFundProfile';
import NewCFIGraph from 'Latest/Goal/InvestmentCalculator/newCFIGraph';
import AccountSetupAdress from "./AccountSetup/accountSetupAddress";
import { getServiceFee } from 'redux/reducers/payment';
import { onGetUserPendingRiskProfileQuestion } from 'redux/actions';
import ErrModal from 'Latest/MyProfile/ModalV2/errModal';
import { Box, Container, Grid, LinearProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ModalStyle } from 'Latest/MyProfile/Style/modalStyle';
import WrongCnicModal from 'Latest/DashboardScreen/GoalDashboard/LeftSection/ModalComponentV2/wrongCnic';
import AssetAllocationV1 from './AssetAllocationV1';
import { isSizeGreaterThan25KB } from 'utils/calculation';
import TopBarLogo from 'Latest/ComponentV1/topBarLogo';
import ExitIcon from "Latest/Images/svg/ExitIcon";
import { MixPannelTracker } from 'Latest/MixPanel/MixPannelTracker';
import AdditionalDetailsForm from 'jsx/containers/V2/AdditionalDetailsFormLatest';
import BankDetailsForm from 'jsx/containers/V2/BankDetailsFormLatest';
import AdditionalDetailsPermanentAddr from 'jsx/containers/V2/AdditionalDetailsPemanentAddr';
import SuccessScreen from 'Latest/PayReceipt/successReceipts';
import PayReceipt from 'Latest/PayReceipt';
import RetirementGraph from 'Latest/Goal/InvestmentCalculator/retirementGraph';
import RetirementConfirmation from './RiskProfileConfirmation/retirementConfirmation';
import VpsInfo from './vpsInfo';

const riskProfileFormikInitialValues: any = {};
const accountSetupFormikInitialValues: any = {};

const WizardFormBody = ({
  goalId,
  goalName,
  goalValue,
  handleGoalBack,
  loading,
  proceedButtonHandler,
}: WizardFormBodyProps) => {
  const dispatch = useAppDispatch();
  const { isGIAllowed, user, userAccountDetails } = useAppSelector(state => state.auth);
  const { goalIdPost } = useAppSelector(state => state?.portfolio);
  const { dashboardDetail } = useAppSelector((state: any) => state.dashboard);

  const { accountSetupUserQuestions, cashFundProfileUserQuestions, pendingCashFundQuestion, pendingRiskProfileQuestion, riskProfileUserQuestions } = useAppSelector(state => state.questionAnswer);

  const { companies } = useAppSelector(state => state.employer);
  const [InvestmentAmount, setInvestmentAmount] = useState(0);
  const age = new Date().getFullYear() - new Date(user?.dob ?? '').getFullYear();
  const [isLoading, setLoading] = useState(true);
  const [isSavingPlanButton, setIsSavingPlanButton] = useState(true);
  const [userNICFront, setUserNICFront] = useState([]);
  const [userNICBack, setUserNICBack] = useState([]);
  const [imageError, setImageError] = useState(false);
  const [activeStep, setActiveStep] = useState<IStep>();
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
  const [chooseInvestmentActiveStep, setChooseInvestmentActiveStep] = useState<number>(0);
  const [riskProfileActiveStep, setRiskProfileActiveStep] = useState<number>(0);
  const [accountSetupActiveStep, setAccountSetupActiveStep] = useState<number>(0);

  const [interestData, setInterestData] = useState<any>({});
  const [isLoadingCNIC, setIsLoadingCNIC] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [disableRiskNext, setDisableRiskNext] = useState(false);
  const [previousRiskScore, setPreviousRiskScore] = useState(true);

  const [checkNic, setCheckNic] = useState(userAccountDetails?.isAccountSetupComplete);
  const [checkAddress, setAddress] = useState(userAccountDetails?.permanentAddress);
  const [checkMotherName, setCheckMotherName] = useState(userAccountDetails?.motherName);
  const [checkIban, setCheckIban] = useState(userAccountDetails?.iban);

  const [showUpdateRiskProfile, setShowUpdateRiskProfile] = useState(false)
  const [showUpdateCashfund, setShowUpdateCashfund] = useState(false)
  const { cashFundSummary, investPortfolioAlloc, personalizeScore } = useAppSelector(state => state.sharedData);
  const [openErrModal, setOpenErrModal] = useState(false);
  const [openWrongCnicModal, setOpenWrongCnicModal] = useState(false);

  const { goBack, location, push } = useHistory();

  const loc: any = location?.state
  console.log(loc,"RedirectLink")
  const theme = useTheme();

  const smScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    formikVP.setFieldValue('goalIdPost', goalIdPost);
    return () => {
      if (goalId == CASH_FUND_ID) {
        dispatch(goalIdAction(''))
        dispatch(goalFlowIdAction(''))
        dispatch(goalFlowPathAction(''))
      }
    };
  }, [])


  const handleAPIsOnStart = () => {
    // dispatch(onGetPersonalisedRiskCategory())
    dispatch(getServiceFee());
    dispatch(onGetUserQuestionAnswers(user?.id, QuestionAnswerCategoryEnum.RiskProfile));
    dispatch(onGetUserQuestionAnswers(user?.id, QuestionAnswerCategoryEnum.CashFund));
    dispatch(onGetUserQuestionAnswers(user?.id, QuestionAnswerCategoryEnum.TermCondition));


    dispatch(onGetAllUserQuestionAnswers(8));
    // dispatch(onGetUserPendingRiskProfileQuestion(PendingQuestionAnswerCategoryEnum.TermCondition))


    dispatch(onGetUserPendingRiskProfileQuestion(PendingQuestionAnswerCategoryEnum.RiskProfile))
    dispatch(onGetUserPendingRiskProfileQuestion(PendingQuestionAnswerCategoryEnum.CashFund))

    dispatch(onGetCashFundSummary())
    // dispatch(onGetUserPendingRiskProfileQuestion(PendingQuestionAnswerCategoryEnum.CashFund))

  }
  useEffect(() => {
    handleAPIsOnStart()
  }, [])

  interface IStep {
    label: string;
    ifSkip?: () => boolean;
    onNext?: () => void;
  }

  const genInvestwizardSteps: IStep[] = [
    {
      label: WizardStepsEnum.Step_ChooseInvestment,
    },
    {
      label: WizardStepsEnum.Step_SavingPlan
    },
    {
      label: WizardStepsEnum.Step_RiskProfileConfirmation,
    },
    {
      label: WizardStepsEnum.Update_Question,
    },
    {
      label: WizardStepsEnum.Step_RiskProfile,
    },
    {
      label: WizardStepsEnum.Step_Portfolio,
    },
    {
      label: WizardStepsEnum.Step_CompleteYourAccount,
    },
    {
      label: WizardStepsEnum.Step_Account_Setup_Form,
    },
    {
      label: WizardStepsEnum.Step_Address,
    },
    {
      label: WizardStepsEnum.Step_Additional_Details,
    },
    {
      label: WizardStepsEnum.Step_Additional_Details_Permanent_Addr,
    },
    {
      label: WizardStepsEnum.Step_Review_Info,
    },
    {
      label: WizardStepsEnum.Step_Term_Condition,
    },
    {
      label: WizardStepsEnum.Step_PayNow,
    },
  ];

  const cashFundWizardSteps: IStep[] = [
    {
      label: WizardStepsEnum.Step_SavingPlan
    },
    {
      label: WizardStepsEnum.Step_Portfolio,
    },
    {
      label: WizardStepsEnum.Step_RiskProfileConfirmation,
    },
    {
      label: WizardStepsEnum.Update_Question,
    },
    {
      label: WizardStepsEnum.Step_RiskProfile,
    },
    {
      label: WizardStepsEnum.Step_CompleteYourAccount,
    },
    {
      label: WizardStepsEnum.Step_Account_Setup_Form,
    },
    {
      label: WizardStepsEnum.Step_Address,
    },
    {
      label: WizardStepsEnum.Step_Bank_Details,
    },
    {
      label: WizardStepsEnum.Step_Review_Info,
    },
    {
      label: WizardStepsEnum.Step_Term_Condition,
    },
    {
      label: WizardStepsEnum.Step_PayNow,
    },
  ];

  const retirementWizardSteps: IStep[] = [
    {
      label: WizardStepsEnum.Step_SavingPlan
    },
    {
      label: WizardStepsEnum.Step_RiskProfileConfirmation,
    },
    {
      label: WizardStepsEnum.step_VPSInfo,
    },
    {
      label: WizardStepsEnum.Update_Question,
    },
    {
      label: WizardStepsEnum.Step_RiskProfile,
    },
    {
      label: WizardStepsEnum.Step_Portfolio,
    },
    {
      label: WizardStepsEnum.Step_CompleteYourAccount,
    },
    {
      label: WizardStepsEnum.Step_Account_Setup_Form,
    },
    {
      label: WizardStepsEnum.Step_Address,
    },
    {
      label: WizardStepsEnum.Step_Bank_Details,
    },
    {
      label: WizardStepsEnum.Step_Review_Info,
    },
    {
      label: WizardStepsEnum.Step_Term_Condition,
    },
    {
      label: WizardStepsEnum.Step_PayNow,
    },

  ];

  const [questionAnswers, setQuestionAnswers] = useState(riskProfileUserQuestions)
  const [wizardSteps, setWizardSteps] = useState(genInvestwizardSteps)

  useEffect(() => {
    if (goalId === CASH_FUND_ID) {
      const frequencyAge: number = userAccountDetails?.identityCard?.age;
      formikVP.setFieldValue('userAge', frequencyAge);

      formikVP.setFieldValue('plan_type', "MonthlyContributions");
      formikVP.setFieldValue('desired_retirement_age', 3);
      formikVP.setFieldValue('initial_amount', 1000);
      formikVP.setFieldValue('monthly_saving', 500);

      // setQuestionAnswers({ questionAnswer: pendingCashFundQuestion })
      const CASH_FUNF_QUES_ANS = cashFundProfileUserQuestions
      setQuestionAnswers(CASH_FUNF_QUES_ANS)
      setWizardSteps(cashFundWizardSteps)
      setActiveStep(cashFundWizardSteps.at(0));
    } else if (goalId === RETIREMENT_ID) {
      const frequencyAge: number = userAccountDetails?.identityCard?.age;
      formikVP.setFieldValue('userAge', frequencyAge);

      formikVP.setFieldValue('plan_type', "MonthlyContributions");
      formikVP.setFieldValue('desired_retirement_age', 3);
      formikVP.setFieldValue('initial_amount', 10000);
      formikVP.setFieldValue('monthly_saving', 500);

      setQuestionAnswers(riskProfileUserQuestions)
      setWizardSteps(retirementWizardSteps)
      setActiveStep(retirementWizardSteps.at(0));
    } else {
      // setQuestionAnswers({ questionAnswer: pendingRiskProfileQuestion })
      setQuestionAnswers(riskProfileUserQuestions)
      setWizardSteps(genInvestwizardSteps)
      setActiveStep(genInvestwizardSteps.at(0));
    }
    setActiveStepIndex(0);
  }, [cashFundProfileUserQuestions, riskProfileUserQuestions]);


  useEffect(() => {
    if (
      accountSetupUserQuestions == undefined ||
      !(
        accountSetupUserQuestions?.questionAnswer?.length >
        accountSetupActiveStep
      )
    ) {
      setShowDisclaimer(true);
    }
  }, [accountSetupUserQuestions]);

  const formikVP = useFormik({
    initialValues: { goalId, ...voluntaryPension },
    onSubmit: values => {
      // console.log('');
    },
    validationSchema: VPSGoalFunc()
  });

  const formikOPS = useFormik({
    enableReinitialize: true,
    initialValues: occupationalPension,
    onSubmit: values => {
      // console.log('');
    },
    validationSchema: OccupationalGoal
  });

  useEffect(() => {
    formikVP.setFieldValue('IBANnumber', userAccountDetails?.iban)
    formikVP.setFieldValue('accountType', goalId === CASH_FUND_ID ? "Cashfund" : "Invest")
  }, [])

  useEffect(() => {
    if (goalId === 4) {
      formikVP.setFieldValue('goal_name', goalName);
    }
  }, [goalId])

  const submitGoal = async () => {
    const investProfile: any = formikVP?.values?.selectedPorfolio == null ? formikVP?.values?.recommendedPorfolio : formikVP?.values?.selectedPorfolio
    const bodyAddGoal = {
      "goalName": goalId === 4 ? formikVP.values.goal_name : goalName,
      "riskProfileScore": personalizeScore,
      "initialInvestment": formikVP.values.initial_amount,
      "durationMonths": formikVP.values.number_of_payments,
      "periodicContribution": formikVP.values.monthly_saving,
      "targetAmount": formikVP?.values?.plan_type == 'AccumulateAmount' ? formikVP.values.retirement_amount : formikVP.values.future_values,
      "isAccumulate": formikVP?.values?.plan_type == 'AccumulateAmount',
      "idInvestProfile": investProfile?.idInvestProfile,


    }

    const bodyCashFund = {
      "initialAmount": formikVP.values.initial_amount,
      "monthlyContribution": {
        "savingAmountPerMonth": formikVP.values.retirement_amount,
        "totalAmount": formikVP.values.future_values,
        "numberOfMonths": formikVP.values.number_of_payments,
      },
      "idCashFund": cashFundSummary.id,
      "id": formikVP.values.goalIdPost
    }

    const bodyVpsNew = {
      'initialInvestmentAmount': formikVP.values.initial_amount,
      'monthlyContributionAmount': formikVP.values.monthly_saving,
      'retirementDurationInMonths': formikVP.values.number_of_payments,
      'projectedAmount': formikVP.values.future_values,
      'idVpsProfile': investProfile?.idInvestProfile,
      'riskProfileScore': personalizeScore,
      'id': formikVP.values.goalIdPost,
      'annualIncome': formikVP.values.annual_taxable_income
    }

    let body = null;

    if (goalId === CASH_FUND_ID) body = bodyCashFund;
    else if (goalId === RETIREMENT_ID) body = bodyVpsNew;
    else body = bodyAddGoal;

    dispatch(addGoalBodyAction(body));

    const initialAmountCheck: any = formikVP.values.initial_amount

    let insurance = 0;
    insurance = Big(initialAmountCheck)
      .add(formikVP.values.monthly_saving || 0)
      .toNumber();
    localStorage.setItem('InitialAmount', initialAmountCheck);
    localStorage.setItem('InvestmentAmount', String(insurance));

  };

  const riskProfileFormikValidationSchema: any = {};

  const riskProfileValidations_ = (questionAnswer: any) => {

    if (questionAnswer?.regex) {
      riskProfileFormikValidationSchema[questionAnswer?.id] = Yup.number()
        // .matches(new RegExp(questionAnswer.regex), 'Invalid Input')
        .required(`Please provide the required data.`)
        .max(100000000, 'Amount should be less than 1 billion').nullable();
    }

    else {
      const nestedQuestionId = `${questionAnswer?.id}-nested`;
      const questionId = questionAnswer?.id;
      let filterNestedQuestion: any = []

      riskProfileFormikValidationSchema[questionId] = Yup.string().required(`Please provide the required data.`);

      if (questionId) {
        riskProfileFormikValidationSchema[nestedQuestionId] = Yup.mixed().when(questionId, {
          is: (value: any) => {
            filterNestedQuestion = questionAnswer?.answer?.answerOptions.filter((i: any) => i.value == value);
            const checkNestedQuestion = filterNestedQuestion[0]?.answer == null;
            return !checkNestedQuestion;
          },
          then: Yup.mixed().test('', '', function (value: any, context) {
            if (filterNestedQuestion[0]?.answer?.answerFieldType === "CheckBox") {
              if (value == undefined || value.length < 2) {
                return this.createError({
                  message: "Select at least 2 options"
                });
              }
            } else if (filterNestedQuestion[0]?.answer?.answerFieldType === "RadioButton") {
              if (value == "" || value == undefined) {
                return this.createError({
                  message: "Please provide the required data."
                });
              }
              return true;
            }
            return false; // Parse value as integer before comparison
          }),
        });
      }
    }
  };

  let nestedQuestion_1 = {};
  let nestedQuestion_2 = {};

  if (questionAnswers?.questionAnswer?.length) {
    questionAnswers?.questionAnswer?.map((item: any, index: any) => {
      riskProfileFormikInitialValues[item?.id] = '';
    });
    if (questionAnswers?.questionAnswer?.length) {
      riskProfileValidations_(
        questionAnswers?.questionAnswer[riskProfileActiveStep]
      );
    }
  }

  const nextStep = (currentStep?: IStep, skip = 1) => {
    const index = wizardSteps.findIndex((step, index, steps) => {
      return (currentStep?.label ?? activeStep?.label) === step.label;
    });

    if (index + 1 == wizardSteps.length) {
      return;
    }

    const step = wizardSteps.at(index + skip);
    activeStep?.onNext?.();

    if (!(step?.ifSkip?.() === true)) {
      setActiveStep(step);
      setActiveStepIndex(index + 1);
    } else {
      nextStep(step);
    }
  };

  const previousStep = (currentStep?: IStep, skip = 1) => {
    const index = wizardSteps.findIndex((step, index, steps) => {
      return (currentStep?.label ?? activeStep?.label) === step.label;
    });

    if (index <= 0) {
      return;
    }

    const step = wizardSteps.at(index - skip);

    if (!(step?.ifSkip?.() === true)) {
      setActiveStep(step);
      setActiveStepIndex(index - 1);
      setAccountSetupActiveStep(0);
      setRiskProfileActiveStep(0);
    } else {
      previousStep(step);
    }
  };

  const handleRecommendedPortfolio = () => {
    const findCategory = investPortfolioAlloc?.find((item: any) => personalizeScore >= item?.minimumRiskScore && personalizeScore <= item.maximumRiskScore)
    formikVP.setFieldValue("recommendedPorfolio", findCategory)
  }

  const { user: { email } } = useAppSelector((state: any) => state.auth);

  useEffect(() => {
    if (personalizeScore > 0) {
      handleRecommendedPortfolio()
    }
  }, [personalizeScore])

  const formikRiskProfile = useFormik({
    initialValues: {
      ...riskProfileFormikInitialValues
    },
    onSubmit: values => {
      const questionId = questionAnswers?.questionAnswer[riskProfileActiveStep - 1]?.id;
      const questionIdNested = `${questionAnswers?.questionAnswer[riskProfileActiveStep - 1]?.id}-nested`;
      const question = questionAnswers?.questionAnswer[riskProfileActiveStep - 1]?.question;

      let body: any = {
        investorCode: user.id,
        investorQueriesDto: [
          {
            id: questionAnswers?.questionAnswer[riskProfileActiveStep - 1].id,
            answer: {
              answerOptions: [
                {
                  id: 0,
                  value: values[questionId].toString()
                }
              ]
            },
            riskProfileCategoryDto: {
              id: questionAnswers?.questionAnswer[riskProfileActiveStep]?.category?.id
            }
          }
        ]
      };

      if (values[questionIdNested]) {
        let nestedValues: any = [];
        if (questionIdNested === '636c51ca1833d0b8ffb404e4-nested') {
          nestedValues = values[questionIdNested];
        } else {
          const nestedArr = Array.isArray(values[questionIdNested]) ? values[questionIdNested] : [`${values[questionIdNested]}`]  //checking its an array or string due to checkbox and radioField
          nestedValues = nestedArr?.map(
            (item: any, index: number) => {
              return {
                value: item
              };
            }
          );
        }
        body = {
          investorCode: user.id,
          investorQueriesDto: [
            {
              id: questionAnswers?.questionAnswer[riskProfileActiveStep - 1].id,
              answer: {
                answerOptions: [
                  {
                    id: 0,
                    value: values[questionId].toString(),
                    answer: { answerOptions: nestedValues }
                  }
                ]
              },
              riskProfileCategoryDto: {
                id: questionAnswers?.questionAnswer[riskProfileActiveStep - 1]?.category?.id
              }
            }
          ]
        };
      }

      dispatch(onPostUserAnswersRiskProfile(body));

      MixPannelTracker({ eventName: question, email })

      if (riskProfileActiveStep > questionAnswers?.questionAnswer?.length - 1) {
        const numbersOfPayment = formikVP.values.number_of_payments;
        if (goalId != CASH_FUND_ID) {
          const isVps = goalId == RETIREMENT_ID
          dispatch(onGetPersonalisedScore(isVps));
        }

        // nextStep();
        const checkSkipSteps = () => {
          if (!userAccountDetails?.permanentAddress) {
            return 3
          }
          else if (!userAccountDetails?.iban) {
            return 4
          }
          else {
            return 5
          }
        }

        const skipStep = goalId === CASH_FUND_ID ? checkSkipSteps() : 1;

        if (userAccountDetails?.isAccountSetupComplete && checkNic) {
          nextStep(undefined, skipStep);
        } else {
          nextStep();
        }
      }

    },
    // validationSchema: cashfundProfileValidations_(questionAnswers),
    validationSchema: Yup.object({
      ...riskProfileFormikValidationSchema
    })
  });

  if (questionAnswers?.questionAnswer?.length) {
    questionAnswers?.questionAnswer[
      riskProfileActiveStep
    ]?.answer?.answerOptions?.map((item: any, index: number) => {
      const questionId =
        questionAnswers?.questionAnswer[riskProfileActiveStep]?.id;
      if (item.value === formikRiskProfile.values[questionId]) {
        if (item.answer) {
          nestedQuestion_1 = item.answer;
        } else {
          nestedQuestion_1 = {};
          if (formikRiskProfile.values[`${questionId}-nested`]) {
            formikRiskProfile.setFieldValue(`${questionId}-nested`, undefined);
          }
        }
      }
    });
  }

  const accountSetupFormikValidationSchema: any = {};

  const accountSetupValidations_ = (questionAnswer: any) => {
    if (questionAnswer?.regex) {
      accountSetupFormikValidationSchema[questionAnswer?.id] = Yup.string()
        .matches(new RegExp(questionAnswer.regex), 'Invalid Input')
        .required(`Field is required.`);
    } else {
      accountSetupFormikValidationSchema[questionAnswer?.id] =
        Yup.string().required(`Field is required.`);
    }
  };

  if (accountSetupUserQuestions?.questionAnswer?.length) {
    accountSetupUserQuestions?.questionAnswer?.map((item: any, index: any) => {
      accountSetupFormikInitialValues[item?.id] = '';
    });
    if (accountSetupUserQuestions?.questionAnswer?.length) {
      accountSetupValidations_(
        accountSetupUserQuestions?.questionAnswer[accountSetupActiveStep]
      );
    }
  }

  const formikAccountSetup = useFormik({
    initialValues: {
      ...accountSetupFormikInitialValues
    },
    onSubmit: values => {
      const questionId =
        accountSetupUserQuestions?.questionAnswer[accountSetupActiveStep - 1]
          ?.id;
      const questionIdNested = `${accountSetupUserQuestions?.questionAnswer[accountSetupActiveStep - 1]
        ?.id
        }-nested`;
      let body: any = {
        category:
          accountSetupUserQuestions?.questionAnswer[accountSetupActiveStep - 1]
            ?.category?.id,
        questionId:
          accountSetupUserQuestions?.questionAnswer[accountSetupActiveStep - 1]
            .id,
        answers: [
          {
            value: values[questionId].toString()
          }
        ]
      };
      if (values[questionIdNested]) {
        const nestedValues = values[questionIdNested].map(
          (item: any, index: number) => {
            return {
              value: item
            };
          }
        );
        body = {
          category:
            accountSetupUserQuestions?.questionAnswer[
              accountSetupActiveStep - 1
            ]?.category?.id,
          questionId:
            accountSetupUserQuestions?.questionAnswer[
              accountSetupActiveStep - 1
            ].id,
          answers: [
            {
              value: values[questionId].toString(),
              answers: nestedValues
            }
          ]
        };
      }

      // dispatch(onPostUserAnswers(body, user?.id));

      if (
        accountSetupActiveStep ===
        accountSetupUserQuestions?.questionAnswer?.length - 1
      ) {
        nextStep();
      } else {
        setAccountSetupActiveStep(accountSetupActiveStep + 1);
      }
    },
    validationSchema: Yup.object({
      ...accountSetupFormikValidationSchema
    })
  });

  const formikAddress = useFormik({
    initialValues: addressInitialValue,
    onSubmit: values => {
      const permanentAddress = [values.permanentAddress1, values.permanentAddress2].filter(
        (x: any) => x != ''
      );
      const mailingAddress = [values.mailingAddress1, values.mailingAddress2].filter(
        (x: any) => x != ''
      );

      const body = {
        "permanentAddress": {
          "lines": permanentAddress,
          "city": values?.cityPermanent?.value,
          "province": values?.provincePermanent?.value
        },
        "mailingAddress": {
          "lines": mailingAddress,
          "city": values?.cityMailing?.value,
          "province": values?.provinceMailing?.value
        }
      }

      const onSuccess = (message: any, response: any) => {
        MixPannelTracker({ eventName: mixPannelTrackerEventName.address_submitted, email, status: "success" })
        dispatch(onGetAccountSetup())
        nextStep()
      }
      const onFail = (message: string, response: any) => {
        MixPannelTracker({ eventName: mixPannelTrackerEventName.address_submitted_fail, email, status: "fail" })

      }
      dispatch(onPostAddressV1(body, onSuccess, onFail));
    },
    validationSchema: addressFormSchema
  });


  if (accountSetupUserQuestions?.questionAnswer?.length) {
    accountSetupUserQuestions?.questionAnswer[accountSetupActiveStep]?.answer?.answerOptions?.map((item: any, index: number) => {
      const questionId = accountSetupUserQuestions?.questionAnswer[accountSetupActiveStep]?.id;
      if (item.answer) {
        nestedQuestion_2 = item.answer;
      } else {
        nestedQuestion_2 = {};
        if (formikAccountSetup.values[`${questionId}-nested`]) {
          formikAccountSetup.setFieldValue(`${questionId}-nested`, undefined);
        }
      }
    });
    if (formikAccountSetup?.values['63693be88664ebf895e12490-nested']?.length > 0 && !formikAccountSetup?.values['63693be88664ebf895e12490']) {
      formikAccountSetup?.setFieldValue('63693be88664ebf895e12490', formikAccountSetup?.values['63693bac8664ebf895e1248f']);
    }
  }
  const [modalContent, setModalContent] = useState<any>('');

  const ContentFailedModal = (customMess: any) => {
    return (
      <Grid item xs={12} sx={ModalStyle.wrapStyles}>
        <Typography sx={ModalStyle.headStyle}>Your account already exists</Typography>
        <Typography sx={ModalStyle.subheadStyle}>
          {customMess}
        </Typography>
      </Grid>
    )
  }

  useEffect(() => {
    setModalContent(ContentFailedModal("It seems that you’ve already registered with this CNIC. Investors can only register one Mahaana account."))
  }, [])

  const formikNIC = useFormik({
    enableReinitialize: true,
    initialValues: {
      UserNICFront: '',
      UserNICBack: '',
      consentCheck: false
    },
    onSubmit: values => {
      const userNICFront: any = values.UserNICFront; // Replace with the actual file data for UserNICFront
      const userNICBack: any = values.UserNICBack;
      const shouldUpdateUser = isSizeGreaterThan25KB(userNICFront, userNICBack);

      const formData = new FormData();
      formData.append('UserNicFront', userNICFront);
      formData.append('UserNicBack', userNICBack);
      formData.append("UpdateUser", "true")
      // formData.append("UpdateUser", String(shouldUpdateUser))

      const onFail = (message: string, response: any) => {
        setIsLoadingCNIC(false);

        // if (shouldUpdateUser) {
        if (response?.status == 409) {
          setModalContent(ContentFailedModal("It seems that you’ve already registered with this CNIC. Investors can only register one Mahaana account."))
          setOpenErrModal(true)
        } else {
          dispatch(onGetAccountSetup());
          setOpenWrongCnicModal(true)
        }
        // } else {
        //   nextStep()
        // }

      };
      const onSuccess = (message: string, response: any) => {
        // setAccountSetupActiveStep(accountSetupActiveStep + 1);

        dispatch(onGetAccountSetup());
        setTimeout(() => {
          nextStep()
          setIsLoadingCNIC(false);
        }, 1000);


        return;
      };
      MixPannelTracker({ eventName: mixPannelTrackerEventName.cnic_images_uploaded, email })
      setIsLoadingCNIC(true);
      dispatch(onSubmitDocuments(formData, onSuccess, onFail));

    },
    validationSchema: CnicValidation
  });

  const handleInterestCompute = (values: Record<string, string | number>) => {
    const computedIntrest = Compound(
      values.initial_amount,
      values.monthly_saving,
      65 - age,
      0.096,
      12
    );
    setInterestData(computedIntrest);
  };

  const { search } = useLocation();
  const param = search.slice(6);
  const isOPS = param === 'employer';

  const selectedCompany = companies.find((item) => {
    return item.id === parseInt(formikOPS.values.employer);
  });

  const plannedSavingData = () =>
    isOPS
      ? {
        initialAmount: 0,
        monthlySaving: ((selectedCompany?.cont_prec ?? 1) * formikOPS.values.salary) / 100
      }
      : {
        initialAmount: formikVP.values.initial_amount,
        monthlySaving: formikVP.values.monthly_saving,
        retirementAmount: Number(formikVP.values.retirement_amount),
        planType: formikVP.values.plan_type,
        annualTaxableIncome: formikVP.values.annual_taxable_income
      };

  const quesId = questionAnswers?.questionAnswer?.[riskProfileActiveStep]?.id;
  const isDisabledRisprofileNextBtn = formikRiskProfile?.values[quesId] == undefined ? true : formikRiskProfile?.values[quesId] == '' ? true : false;
  const isDisabledRisprofileNextBtnError = formikRiskProfile?.errors?.[questionAnswers?.questionAnswer?.[riskProfileActiveStep]?.id] == undefined ? false : true;
  const valueRisk = formikRiskProfile?.values[quesId];
  const nestedAnswer = questionAnswers?.questionAnswer?.length && questionAnswers?.questionAnswer[riskProfileActiveStep]?.answer?.answerOptions?.find((item: any, index: number) => item.value === valueRisk);
  const isDisabledNestedRisprofileNextBtn = nestedAnswer?.answer == null ? false : formikRiskProfile?.values[`${quesId}-nested`] == undefined ? true : formikRiskProfile?.values[`${quesId}-nested`]?.length > 1 ? false : true;

  const GenInvestArray = [
    {
      type: WizardStepsEnum.Step_ChooseInvestment,
      comp: <ChooseInvestment
        formik={formikVP}
        goalId={goalId}
        chooseInvestmentActiveStep={chooseInvestmentActiveStep}
        handleGoalBack={handleGoalBack}
        setChooseInvestmentActiveStep={setChooseInvestmentActiveStep}
        loading={loading}
        nextStep={nextStep}
        handleAPIsOnStart={handleAPIsOnStart}
        questionAnswers={questionAnswers?.questionAnswer}
      />
    },
    {
      type: WizardStepsEnum.Step_SavingPlan,
      comp: <GenInvestGraph
        previousStep={previousStep}
        loading={loading}
        nextStep={nextStep}
        setLoading={setLoading}
        submitGoal={submitGoal}
        formik={isOPS ? formikOPS : formikVP}
        handleInterestCompute={handleInterestCompute}
        isEmployer={isOPS}
        RedirectLink={loc?.RedirectLink}
        {...plannedSavingData()}
      />
    },
    {
      type: WizardStepsEnum.Step_RiskProfileConfirmation,
      comp: <>
        <RiskProfileConfirmation previousStep={previousStep} nextStep={nextStep} goalId={goalId} RedirectLink={loc?.RedirectLink} />
      </>
    },
    {
      type: WizardStepsEnum.Update_Question,
      comp: <>
        <UpdateRiskProfile
          formikRiskProfile={formikRiskProfile} setShowUpdateRiskProfile={setShowUpdateRiskProfile}
          previousStep={previousStep} nextStep={nextStep}
          formik={isOPS ? formikOPS : formikVP} goalValue={goalValue} goalId={goalId}
        />
      </>
    },
    {
      type: WizardStepsEnum.Step_RiskProfile,
      comp:
        <>
          {questionAnswers?.questionAnswer?.length && (
            <form>
              <DynamicQuestionAnswers
                activeStep={riskProfileActiveStep || 0}
                formik={formikRiskProfile}
                nestedQuestion={nestedQuestion_1}
                questionAnswers={questionAnswers}
                riskProfileActiveStep={riskProfileActiveStep}
                previousStep={previousStep}
                setRiskProfileActiveStep={setRiskProfileActiveStep}
                isDisabledRisprofileNextBtn={isDisabledRisprofileNextBtn}
                isDisabledRisprofileNextBtnError={isDisabledRisprofileNextBtnError}
                isDisabledNestedRisprofileNextBtn={isDisabledNestedRisprofileNextBtn}
                formikRiskProfile={formikRiskProfile}
                riskProfileUserQuestions={questionAnswers}
                totalQuestion={questionAnswers?.questionAnswer?.length}
              />
            </form>
          )}
        </>
    },
    {
      type: WizardStepsEnum.Step_Portfolio,
      comp:
        <>
          <AssetAllocationV1
            previousStep={previousStep}
            loading={loading}
            submitGoal={submitGoal}
            nextStep={nextStep}
            formik={isOPS ? formikOPS : formikVP}
            interestData={interestData}
            investmentAmount={(amount: any) => setInvestmentAmount(amount)}
            isEmployer={isOPS}
            handleInterestCompute={handleInterestCompute}
            initialAmount={formikVP.values.initial_amount}
            monthlySaving={formikVP.values.monthly_saving}
            retirementAmount={Number(formikVP.values.retirement_amount)}
            planType={formikVP.values.plan_type}
            checkNic={checkNic}
            pendingRiskProfileQuestion={pendingRiskProfileQuestion}
            setShowUpdateRiskProfile={setShowUpdateRiskProfile}
            RedirectLink={loc?.RedirectLink}
            checkMotherName={checkMotherName}
            checkAddress={checkAddress}
            goalId={goalId}
            checkIban={checkIban}

          />

        </>
    },
    {
      type: WizardStepsEnum.Step_CompleteYourAccount,
      comp: <AccountSetup
        accountSetupActiveStep={accountSetupActiveStep}
        backNICFile={userNICBack}
        completeAccount={completeAccount}
        formik={formikNIC}
        frontNICFile={userNICFront}
        goalId={goalId}
        imageError={imageError}
        isLoadingCNIC={isLoadingCNIC}
        loading={loading}
        previousStep={previousStep}
        setImageError={setImageError}
        setUserNICBack={setUserNICBack}
        setUserNICFront={setUserNICFront}
      />
    },
    {
      type: WizardStepsEnum.Step_Account_Setup_Form,
      comp: <AccountSetupForm
        previousStep={previousStep}
        loading={loading}
        nextStep={nextStep}
        formikVP={isOPS ? formikOPS : formikVP}
        goalId={goalId}
      />
    },
    {
      type: WizardStepsEnum.Step_Address,
      comp: <AccountSetupAdress
        formik={formikAddress}
        goalId={goalId}
        loading={loading}
        nextStep={nextStep}
        previousStep={previousStep}
        checkNic={checkNic}
        checkAddress={checkAddress}
        checkMotherName={checkMotherName}
      />
    },
    {
      type: WizardStepsEnum.Step_Additional_Details,
      comp: <AdditionalDetailsForm
        previousStep={previousStep}
        loading={loading}
        nextStep={nextStep}
        formikVP={isOPS ? formikOPS : formikVP}
        goalId={goalId}
        checkNic={checkNic}
        checkAddress={checkAddress}
        checkMotherName={checkMotherName}
      />
    },
    {
      type: WizardStepsEnum.Step_Additional_Details_Permanent_Addr,
      comp: <AdditionalDetailsPermanentAddr
        previousStep={previousStep}
        loading={loading}
        nextStep={nextStep}
        formikVP={isOPS ? formikOPS : formikVP}
        goalId={goalId}
      />
    },
    {
      type: WizardStepsEnum.Step_Review_Info,
      comp: <ReviewInfo checkIban={checkIban} checkMotherName={checkMotherName} checkAddress={checkAddress} previousStep={previousStep} loading={loading} nextStep={nextStep} goalId={goalId} goalValue={goalValue} goalName={goalName} checkNic={checkNic} formik={isOPS ? formikOPS : formikVP} submitGoal={submitGoal} />
    },
    {
      type: WizardStepsEnum.Step_Term_Condition,
      comp: <TermCondition formik={isOPS ? formikOPS : formikVP} previousStep={previousStep} goalId={goalId} loading={loading} nextStep={nextStep} submitGoal={submitGoal} />
    },
    {
      type: WizardStepsEnum.Step_PayNow,
      comp: <PayNow formik={isOPS ? formikOPS : formikVP} previousStep={previousStep} loading={loading} nextStep={nextStep} goalId={goalId} submitGoal={submitGoal} goalValue={goalValue} />
    },
  ]

  const CashfundArray = [
    {
      type: WizardStepsEnum.Step_SavingPlan,
      comp: <NewCFIGraph
        previousStep={goBack}
        // previousStep={previousStep}
        loading={loading}
        nextStep={nextStep}
        setLoading={setLoading}
        submitGoal={submitGoal}
        formik={isOPS ? formikOPS : formikVP}
        handleInterestCompute={handleInterestCompute}
        isEmployer={isOPS}
        RedirectLink={loc?.RedirectLink}
        handleAPIsOnStart={handleAPIsOnStart}
        questionAnswers={questionAnswers?.questionAnswer}
        {...plannedSavingData()}
      />
    },
    {
      type: WizardStepsEnum.Step_Portfolio,
      comp: <CashFundAssetAllocation
        previousStep={previousStep} loading={loading} submitGoal={submitGoal} nextStep={nextStep}
        formik={isOPS ? formikOPS : formikVP}
        interestData={interestData}
        investmentAmount={(amount: any) => setInvestmentAmount(amount)}
        isEmployer={isOPS}
        handleInterestCompute={handleInterestCompute}
        initialAmount={formikVP.values.initial_amount}
        monthlySaving={formikVP.values.monthly_saving}
        retirementAmount={Number(formikVP.values.retirement_amount)}
        planType={formikVP.values.plan_type}
        RedirectLink={loc?.RedirectLink}

      />
    },
    {
      type: WizardStepsEnum.Step_RiskProfileConfirmation,
      comp:
        <>
          <RiskProfileConfirmation previousStep={previousStep} nextStep={nextStep} goalId={goalId} RedirectLink={loc?.RedirectLink} />
        </>
    },
    {
      type: WizardStepsEnum.Update_Question,
      comp: <>
        <UpdateCashFundProfile formikRiskProfile={formikRiskProfile} setShowUpdateRiskProfile={setShowUpdateRiskProfile} previousStep={previousStep} nextStep={nextStep} userAccountDetails={userAccountDetails} checkNic={checkNic} />
      </>
    },
    {
      type: WizardStepsEnum.Step_RiskProfile,
      comp: <>{questionAnswers?.questionAnswer?.length && (
        <form>
          <DynamicQuestionAnswers
            activeStep={riskProfileActiveStep || 0}
            formik={formikRiskProfile}
            nestedQuestion={nestedQuestion_1}
            questionAnswers={questionAnswers}
            riskProfileActiveStep={riskProfileActiveStep}
            previousStep={previousStep}
            setRiskProfileActiveStep={setRiskProfileActiveStep}
            isDisabledRisprofileNextBtn={isDisabledRisprofileNextBtn}
            isDisabledRisprofileNextBtnError={isDisabledRisprofileNextBtnError}
            isDisabledNestedRisprofileNextBtn={isDisabledNestedRisprofileNextBtn}
            formikRiskProfile={formikRiskProfile}
            riskProfileUserQuestions={questionAnswers}
            totalQuestion={questionAnswers?.questionAnswer?.length}
          />
        </form>
      )}</>
    },
    {
      type: WizardStepsEnum.Step_CompleteYourAccount,
      comp: <AccountSetup
        accountSetupActiveStep={accountSetupActiveStep}
        backNICFile={userNICBack}
        completeAccount={completeAccount}
        formik={formikNIC}
        frontNICFile={userNICFront}
        goalId={goalId}
        imageError={imageError}
        isLoadingCNIC={isLoadingCNIC}
        loading={loading}
        previousStep={previousStep}
        setImageError={setImageError}
        setUserNICBack={setUserNICBack}
        setUserNICFront={setUserNICFront}
      />
    },
    {
      type: WizardStepsEnum.Step_Account_Setup_Form,
      comp: <AccountSetupForm
        previousStep={previousStep}
        loading={loading}
        nextStep={nextStep}
        formikVP={isOPS ? formikOPS : formikVP}
        goalId={goalId} />
    },
    {
      type: WizardStepsEnum.Step_Address,
      comp: <AccountSetupAdress
        formik={formikAddress}
        goalId={goalId}
        loading={loading}
        nextStep={nextStep}
        previousStep={previousStep}
        checkNic={checkNic}
        checkAddress={checkAddress}
      />
    },
    {
      type: WizardStepsEnum.Step_Bank_Details,
      comp: <BankDetailsForm
        previousStep={previousStep}
        loading={loading}
        nextStep={nextStep}
        formikVP={isOPS ? formikOPS : formikVP}
        goalId={goalId}
        checkAddress={checkAddress}
        checkIban={checkIban}
      />
    },
    {
      type: WizardStepsEnum.Step_Review_Info,
      comp: <ReviewInfo
        checkAddress={checkAddress}
        previousStep={previousStep}
        loading={loading}
        nextStep={nextStep}
        goalId={goalId} goalValue={goalValue} goalName={goalName} checkNic={checkNic} formik={isOPS ? formikOPS : formikVP}
        submitGoal={submitGoal}
        checkMotherName={checkMotherName}
        checkIban={checkIban}
      />
    },
    {
      type: WizardStepsEnum.Step_Term_Condition,
      comp: <TermCondition formik={isOPS ? formikOPS : formikVP} previousStep={previousStep} goalId={goalId} loading={loading} nextStep={nextStep} submitGoal={submitGoal} />
    },
    {
      type: WizardStepsEnum.Step_PayNow,
      comp: <PayNow previousStep={previousStep} loading={loading} nextStep={nextStep} goalId={goalId} formik={formikVP} submitGoal={submitGoal} goalValue={goalValue} />
    },
  ]

  const RetirementArray = [
    {
      type: WizardStepsEnum.Step_SavingPlan,
      comp: <RetirementGraph
        previousStep={goBack}
        // previousStep={previousStep}
        loading={loading}
        nextStep={nextStep}
        setLoading={setLoading}
        submitGoal={submitGoal}
        formik={formikVP}
        handleInterestCompute={handleInterestCompute}
        isEmployer={isOPS}
        RedirectLink={loc?.RedirectLink}
        handleAPIsOnStart={handleAPIsOnStart}
        questionAnswers={questionAnswers?.questionAnswer}
        {...plannedSavingData()}
      />
    },
    {
      type: WizardStepsEnum.Step_RiskProfileConfirmation,
      comp:
        <>
          <RetirementConfirmation previousStep={previousStep} nextStep={nextStep} goalId={goalId} RedirectLink={loc?.RedirectLink} />
        </>
    },
    {
      type: WizardStepsEnum.step_VPSInfo,
      comp:
        <>
          <VpsInfo previousStep={previousStep} nextStep={nextStep} goalId={goalId} RedirectLink={loc?.RedirectLink} />
        </>
    },
    {
      type: WizardStepsEnum.Update_Question,
      comp: <>
        <UpdateRiskProfile
          formikRiskProfile={formikRiskProfile} setShowUpdateRiskProfile={setShowUpdateRiskProfile}
          previousStep={previousStep} nextStep={nextStep}
          formik={formikVP} goalValue={goalValue} goalId={goalId}
        />
      </>
    },
    {
      type: WizardStepsEnum.Step_RiskProfile,
      comp: <>{questionAnswers?.questionAnswer?.length && (
        <form>
          <DynamicQuestionAnswers
            activeStep={riskProfileActiveStep || 0}
            formik={formikRiskProfile}
            nestedQuestion={nestedQuestion_1}
            questionAnswers={questionAnswers}
            riskProfileActiveStep={riskProfileActiveStep}
            previousStep={previousStep}
            setRiskProfileActiveStep={setRiskProfileActiveStep}
            isDisabledRisprofileNextBtn={isDisabledRisprofileNextBtn}
            isDisabledRisprofileNextBtnError={isDisabledRisprofileNextBtnError}
            isDisabledNestedRisprofileNextBtn={isDisabledNestedRisprofileNextBtn}
            formikRiskProfile={formikRiskProfile}
            riskProfileUserQuestions={questionAnswers}
            totalQuestion={questionAnswers?.questionAnswer?.length}
          />
        </form>
      )}</>
    },
    {
      type: WizardStepsEnum.Step_Portfolio,
      comp:
        <>
          <AssetAllocationV1
            previousStep={previousStep}
            loading={loading}
            submitGoal={submitGoal}
            nextStep={nextStep}
            formik={formikVP}
            interestData={interestData}
            investmentAmount={(amount: any) => setInvestmentAmount(amount)}
            isEmployer={isOPS}
            handleInterestCompute={handleInterestCompute}
            initialAmount={formikVP.values.initial_amount}
            monthlySaving={formikVP.values.monthly_saving}
            retirementAmount={Number(formikVP.values.retirement_amount)}
            planType={"voluntarilyPension"}
            checkNic={checkNic}
            pendingRiskProfileQuestion={pendingRiskProfileQuestion}
            setShowUpdateRiskProfile={setShowUpdateRiskProfile}
            RedirectLink={loc?.RedirectLink}
            checkMotherName={checkMotherName}
            checkAddress={checkAddress}
            goalId={goalId}
            checkIban={checkIban}

          />
        </>
    },
    {
      type: WizardStepsEnum.Step_CompleteYourAccount,
      comp: <AccountSetup
        accountSetupActiveStep={accountSetupActiveStep}
        backNICFile={userNICBack}
        completeAccount={completeAccount}
        formik={formikNIC}
        frontNICFile={userNICFront}
        goalId={goalId}
        imageError={imageError}
        isLoadingCNIC={isLoadingCNIC}
        loading={loading}
        previousStep={previousStep}
        setImageError={setImageError}
        setUserNICBack={setUserNICBack}
        setUserNICFront={setUserNICFront}
      />
    },
    {
      type: WizardStepsEnum.Step_Account_Setup_Form,
      comp: <AccountSetupForm
        previousStep={previousStep}
        loading={loading}
        nextStep={nextStep}
        formikVP={isOPS ? formikOPS : formikVP}
        goalId={goalId} />
    },
    {
      type: WizardStepsEnum.Step_Address,
      comp: <AccountSetupAdress
        formik={formikAddress}
        goalId={goalId}
        loading={loading}
        nextStep={nextStep}
        previousStep={previousStep}
        checkNic={checkNic}
        checkAddress={checkAddress}
      />
    },
    {
      type: WizardStepsEnum.Step_Bank_Details,
      comp: <BankDetailsForm
        previousStep={previousStep}
        loading={loading}
        nextStep={nextStep}
        formikVP={isOPS ? formikOPS : formikVP}
        goalId={goalId}
        checkAddress={checkAddress}
        checkIban={checkIban}
      />
    },
    {
      type: WizardStepsEnum.Step_Review_Info,
      comp: <ReviewInfo
        checkAddress={checkAddress}
        previousStep={previousStep}
        loading={loading}
        nextStep={nextStep}
        goalId={goalId} goalValue={goalValue} goalName={goalName} checkNic={checkNic} formik={isOPS ? formikOPS : formikVP}
        submitGoal={submitGoal}
        checkIban={checkIban}
      />
    },
    {
      type: WizardStepsEnum.Step_Term_Condition,
      comp: <TermCondition formik={formikVP} previousStep={previousStep} goalId={goalId} loading={loading} nextStep={nextStep} submitGoal={submitGoal} />
    },
    {
      type: WizardStepsEnum.Step_PayNow,
      comp: <PayNow previousStep={previousStep} loading={loading} nextStep={nextStep} goalId={goalId} formik={formikVP} submitGoal={submitGoal} goalValue={goalValue} />
    },

  ]

  const currentStep = isGIAllowed ? activeStepIndex + 2 : activeStepIndex + 1;
  const totalSteps = activeStepIndex + 1 ? (wizardSteps.length + 1) : wizardSteps.length;
  const progressValue = (currentStep / totalSteps) * 100;

  const addExtraRoutes = [
    {
      title: smScreen ? "Exit" : "Exit",
      route: null,
      icon: ExitIcon,
      isNavlink: false,
      navDropDown: [],
      target: false,
      onClick: () => push("/"),
      functionTrigger: true
    },
  ]

  return (
    <Box className={WizardStepsEnum.Step_Portfolio === activeStep?.label ? 'pt-0' : 'pt-0'} sx={{ background: "#F8FAFB" }}>

      <TopBarLogo addExtraRoutes={addExtraRoutes} />

      <ErrModal
        handleOpen={() => setOpenErrModal(true)}
        open={openErrModal}
        handleClose={() => setOpenErrModal(false)}
        Content={modalContent} btnTitle={"OK"}
      />

      <WrongCnicModal
        handleClose={() => setOpenWrongCnicModal(false)}
        handleOpen={openWrongCnicModal}
        nextStep={nextStep}
      />

      <Box sx={{ minHeight: "93vh", position: 'relative' }}>

        {goalId === CASH_FUND_ID ? CashfundArray.map((item, i) => {
          return (
            <div key={String(i)} style={{ position: 'relative' }}>
              {item.type === activeStep?.label && item.comp}
            </div>
          )
        }) : goalId === RETIREMENT_ID ? RetirementArray.map((item, i) => {
          return (
            <div key={String(i)} style={{ position: 'relative' }}>
              {item.type === activeStep?.label && item.comp}
            </div>
          )
        }) : GenInvestArray.map((item, i) => {
          return (
            <div key={String(i)} style={{ position: 'relative' }}>
              {item.type === activeStep?.label && item.comp}
            </div>
          )
        })}
        {/* {(activeStep?.label !== WizardStepsEnum.Step_Portfolio) && (
          <Box sx={{ width: '100%', position: 'absolute', top: smScreen ? 25 : 40, borderRadius: "8px 8px 0px 0px", overflow: "hidden" }}>
            <Container maxWidth={'lg'} disableGutters={smScreen}>
              <LinearProgress variant="determinate" color="secondary" value={progressValue} sx={{ borderRadius: "3px 3px 0px 0px" }} />
            </Container>
          </Box>
        )} */}
      </Box>

    </Box>
  );
};

export default WizardFormBody;
interface WizardFormBodyProps {
  goalId: number;
  goalValue: string;
  goalName: string;
  handleGoalBack: any;
  isThisGoalCreated?: boolean | undefined;
  loading: boolean;
  proceedButtonHandler: any;
}

const voluntaryPension = {
  initial_amount: '',
  serviceFees_with_salesTax: '',
  nift_amount: '',
  total_serviceFees_with_amount: '',
  goal_name: '',
  investment_type: 'islamic',
  monthly_exp: '',
  monthly_saving: '',
  plan_type: 'AccumulateAmount',
  future_values: '',
  retirement_amount: '',
  desired_retirement_age: '',
  number_of_payments: '',
  userAge: '',
  years_required: '',
  expected_monthly_saving: '',
  travelPlan: 'Travel Plan',
  intialAmountCount: 1,
  intialAmountAssetAllocation: '',
  FundsType: "Mahaana",
  AmountAssetAllocation: '',
  assetAllocations: [],
  goalIdPost: "",
  IBANnumber: "",
  specificGoalScore: 0,
  accountType: "",
  recommendedPorfolio: null,
  selectedPorfolio: null,
  taxSaving: '',
  annual_taxable_income: "",
};

const occupationalPension = {
  CNIC: '',
  contribution: '',
  employer: '1',
  investment_type: 'islamic',
  salary: 4000
};
