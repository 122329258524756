import React, { useEffect, useState } from 'react'
import UploadCNICWebcam from "./steps/UploadCNICWebcam"
import UploadFaceWebcam from "./steps/UploadFaceWebcam"
import UploadPOI from "./steps/UploadProfOfIncome/index"
import UpgradeSteps from "./steps/UpgradeSteps"
import { Box, Container, Divider, Grid, Typography, useTheme } from '@mui/material';
import TopBarHeading from 'Latest/ComponentV1/topBarHeading';
import Declaration from './steps/Declaration';
import { Field, useFormik } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { onGetAccountSetup, onGetAllUserQuestionAnswers, onGetUserQuestionAnswers, onPostFatcaAndCrsQuestions, onPostSecondTier, onPostUserAnswersRiskProfile } from 'redux/actions';
import { base64ToFile } from 'utils/convertToFile';
import { InputCategoryEnum, QuestionAnswerCategoryEnum, reasonSelected, selectReasonEnum } from '../../constants';
import SubmitModal from 'Latest/ModalV1/submitModal';
import SuccessScreen from './steps/SuccessScreen';
import { useHistory } from 'react-router-dom';
import { addGoalBodyAction, onGetCountries, onPatchNotifications } from 'redux/reducers/sharedStateData';
import { customCard } from 'Latest/GoalDetail/style'
import { tokens } from 'theme';
import FatcaAndCrs from './steps/FatcaAndCrs/index';
import { fatcaValidationSchema } from './steps/FatcaAndCrs/FatcaValidation';

const Index = () => {

    const { push } = useHistory();
    const { userAccountDetails } = useAppSelector(state => state.auth);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => {

        dispatch(onGetCountries());

        userAccountDetails?.kyc?.status == 'ApprovalSucceeded' && push("/")
    }, [])


    const [isOpenModal, setIsOpenModal] = React.useState(false);
    const [currentStep, setCurrentStep] = useState(0);

    const { pendingKYCQuestion } = useAppSelector(state => state.questionAnswer);

    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state: any) => state.auth);
    const { kycDecQuestion } = useAppSelector((state: any) => state.questionAnswer);

    const handleAPiKycQuestion = () => {
        dispatch(onGetUserQuestionAnswers(user?.id, QuestionAnswerCategoryEnum.Kyc))
        dispatch(onGetAllUserQuestionAnswers(QuestionAnswerCategoryEnum.Kyc));
    }

    useEffect(() => {
        dispatch(onGetAccountSetup());
    }, [currentStep])

    useEffect(() => {
        handleAPiKycQuestion()
    }, [])

    const handleNext = () => {
        setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
    };

    const handlePrevious = () => {
        setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
    };

    //WEBCAM STEP1
    const formikUploadCNICWebcam = useFormik({
        enableReinitialize: true,
        initialValues: { userNic: '', nic: '', hash: "" },
        onSubmit: values => {
            handleCnicWebCamAPI()
        },
        validationSchema: Yup.object({
            userNic: Yup.string().required('Image is required'), // Image is required
            nic: Yup.string().required('Image is required') // Image is required
        })

    })

    const formikUploadSelfieWebcam = useFormik({
        enableReinitialize: true,
        initialValues: { selfie: '', hash: "" },
        onSubmit: values => {
            handleSelfieWebCamAPI()
        },
        validationSchema: Yup.object({
            selfie: Yup.string().required('Image is required') // Image is required // Image is required
        })
    })

    const handleCnicWebCamAPI = (saveExit = false) => {
        const formData = new FormData();
        formData.append('DocumentType', "Nic");
        formData.append('Nic', base64ToFile(formikUploadCNICWebcam?.values?.nic));
        formData.append('UserNic', base64ToFile(formikUploadCNICWebcam?.values?.userNic));

        const onSucces = () => {
            if (saveExit) {
                push("myprofile?scroll=true")
            } else {
                handleNext()
            }
        }

        const onFail = (message: string, response: any) => {
            console.log(message, response.status, "OPOOP")
            if (saveExit) {
                push("myprofile?scroll=true")
            }
        }


        dispatch(onPostSecondTier(formData, onSucces, onFail))
    }

    const handleSelfieWebCamAPI = (saveExit = false) => {
        const formData = new FormData();
        formData.append('DocumentType', "Selfie");
        formData.append('Selfie', base64ToFile(formikUploadSelfieWebcam?.values?.selfie));

        const onSucces = () => {
            if (saveExit) {
                push("myprofile?scroll=true")
            } else {
                handleNext()
            }
        }

        const onFail = (message: string, response: any) => {
            console.log(message, response.status, "OPOOP")
            if (saveExit) {
                push("myprofile?scroll=true")
            }
        }


        dispatch(onPostSecondTier(formData, onSucces, onFail))
    }

    //Upload POI STEP2
    const formikUploadPOI = useFormik({
        enableReinitialize: true,
        initialValues: { uploadPoiImg: [] },
        onSubmit: (values: any) => {

            const filterFile = values.uploadPoiImg.filter((i: any) => i.file && i)
            if (filterFile.length > 0) {
                const formData = new FormData();
                formData.append('DocumentType', "IncomeProof");
                filterFile.forEach((imageFile: any, index: number) => {
                    return formData.append(`IncomeProofs`, imageFile.file);
                });

                const onSuccess = () => {
                    handleNext()
                }

                const onFail = (message: string, response: any) => {
                    console.log(message, response.status, "OPOOP")
                }

                dispatch(onPostSecondTier(formData, onSuccess, onFail))

            } else {
                handleNext()
            }
        },
        validationSchema: Yup.object({
            uploadPoiImg: Yup.array().min(1, "Image is required").max(5, 'You can upload a maximum of 5 images')
                .test('fileType', 'Invalid file type. Only PNG, JPG, or PDF files are allowed.', (value: any) => {
                    // Check file extensions
                    const filterFile = value.filter((i: any) => i.file && i)
                    const allowedFileExtensions = ['.png', '.jpg', '.jpeg', '.pdf'];

                    if (filterFile.length > 0) {
                        return filterFile?.every((item: any) => {
                            const fileName = item.documentName
                            const fileExtension = fileName.substr(fileName.lastIndexOf('.')).toLowerCase();
                            return (allowedFileExtensions.includes(fileExtension))
                        });
                    }
                    return true;
                })
                .test('fileSize', "", function (value: any, context) {
                    const filterFile = value.filter((i: any) => i.file && i)    //check those file which was not uploaded earlier
                    const minSize = 1000; // 1 KB
                    const maxSize = 10000000; // 10 MB
                    const filterInvalidFileName = filterFile?.filter((item: any) => item?.file && (item?.file?.size <= minSize || item?.file?.size >= maxSize))

                    if (filterInvalidFileName?.length > 0) {
                        const invalidFileNames = filterInvalidFileName.map((item: any) => item.file.name);
                        const errorMessage = `File size should be 1 KB to 10 MB. Invalid files: ${invalidFileNames.join(', ')}`;
                        return this.createError({
                            message: errorMessage
                        });
                    }
                    return true
                })

        })
    })

    const isFatcaAndCrs: any = userAccountDetails?.fatcaCrs;
    const selectedDefaultOption = reasonSelected[isFatcaAndCrs?.reason]?.reason

    const fatcainitialVals = {
        usResident: isFatcaAndCrs ? isFatcaAndCrs?.resident : "",
        usCitizen: isFatcaAndCrs ? isFatcaAndCrs?.citizen : "",
        hasGreenCard: isFatcaAndCrs ? isFatcaAndCrs?.permanentResident : "",
        hasTaxPayer: isFatcaAndCrs ? isFatcaAndCrs?.taxPayer : "",
        wNineForm: isFatcaAndCrs ? isFatcaAndCrs?.formSubmitted : "",
        hasTaxResident: isFatcaAndCrs ? isFatcaAndCrs?.taxResident : "",
        country: isFatcaAndCrs ? isFatcaAndCrs?.country : "",
        city: isFatcaAndCrs ? isFatcaAndCrs?.city : "",
        hasTaxpayerNum: isFatcaAndCrs ? isFatcaAndCrs?.taxpayerIdentified : "",
        taxPayerNumber: isFatcaAndCrs ? isFatcaAndCrs?.tin : "",
        selectReason: isFatcaAndCrs ? selectedDefaultOption : "",
        confirmAll: [],
    };

    const fatcaFormik: any = useFormik({
        initialValues: {
            ...fatcainitialVals
        },
        enableReinitialize: true,
        onSubmit: value => {
            FatcaApiHandler()
        },
        validationSchema: fatcaValidationSchema
    })

    const FatcaApiHandler = (saveExit = false) => {
        const selectedReason: string = fatcaFormik?.values?.selectReason
        const getReason = selectReasonEnum[selectedReason]?.reason


        const isWNineForm = fatcaFormik.values["hasGreenCard"] === "Yes" || fatcaFormik.values["hasTaxPayer"] === "Yes" || fatcaFormik.values["usCitizen"] === "Yes" || fatcaFormik.values["usResident"] === "Yes"
        const isWnineSubmitted = fatcaFormik?.values?.wNineForm === "Yes";
        const formData: any = new FormData();
        formData.append('Resident', fatcaFormik?.values?.usResident);
        formData.append('Citizen', fatcaFormik?.values?.usCitizen);
        formData.append('PermanentResident', fatcaFormik?.values?.hasGreenCard);
        formData.append('TaxPayer', fatcaFormik?.values?.hasTaxPayer);
        if (!isWNineForm || (isWNineForm && isWnineSubmitted)) {
            formData.append('Form', "");

        } else {
            formData.append('Form', fatcaFormik?.values?.wNineForm);
        }
        formData.append('TaxResident', fatcaFormik?.values?.hasTaxResident);
        if (fatcaFormik?.values?.hasTaxResident === "No") {
            formData.append('TaxpayerIdentified', "");
            formData.append('Country', "");
            formData.append('City', "");
            formData.append('Tin', "");
            formData.append('Reason', "");
        } else {
            formData.append('TaxpayerIdentified', fatcaFormik?.values?.hasTaxpayerNum);
            formData.append('Country', fatcaFormik?.values?.country);
            formData.append('City', fatcaFormik?.values?.city);
            if (fatcaFormik?.values?.hasTaxpayerNum === "No") {
                formData.append('Reason', fatcaFormik?.values?.selectReason ? getReason : "");
                formData.append('Tin', "");
            } else {
                formData.append('Tin', fatcaFormik?.values?.taxPayerNumber);
                formData.append('Reason', "");
            }
        }

        const onSuccessFatca = () => {
            if (saveExit) {
                push("myprofile?scroll=true")
            } else {
                handleNext()
            }
        }

        const onFailFatca = (message: string, response: any) => {
            console.log(message, response.status, "OPOOP")
            if (saveExit) {
                push("myprofile?scroll=true")
            }
        }

        dispatch(onPostFatcaAndCrsQuestions(formData, onSuccessFatca, onFailFatca))
    }


    //Decleration STEP3
    const formikDeclarationInitialValues: any = {};

    if (pendingKYCQuestion?.length) {

        pendingKYCQuestion?.map((item: any) => {
            if (item?.answer?.isAttempted) {
                formikDeclarationInitialValues[item?.id] = item?.answer?.answerOptions[0]?.value;
                if (item?.answer?.answerOptions[0]?.answer?.answerOptions?.length > 0) {
                    formikDeclarationInitialValues[`${item?.id}-nested`] = item?.answer?.answerOptions[0]?.answer?.answerOptions[0]?.value
                }
            } else {
                formikDeclarationInitialValues[item?.id] = ""
            }
        });
    }

    const generateValidationSchema = (questions: any) => {
        const validationSchema: any = {};

        if (questions?.questionAnswer?.length > 0) {
            questions.questionAnswer.forEach((item: any) => {
                const questionId = item?.id;
                const nestedQuestionId = `${questionId}-nested`;

                validationSchema[questionId] = Yup.string().required('Required');

                validationSchema[nestedQuestionId] = Yup.string().when(questionId, {
                    is: (value: any) => {
                        const filterNestedQuestion = item?.answer?.answerOptions.filter((i: any) => i.value == value)
                        const checkNestedQuestion = filterNestedQuestion[0]?.answer == null
                        return !checkNestedQuestion;
                    },
                    then: Yup.string().required('Required'),
                    otherwise: Yup.string(),
                });
            });
        }

        return Yup.object().shape(validationSchema);
    };

    const formikDeclaration = useFormik({
        initialValues: { ...formikDeclarationInitialValues },
        enableReinitialize: true,
        onSubmit: (values) => {
            console.log("")
            setIsOpenModal(true)
        },
        validationSchema: generateValidationSchema(kycDecQuestion), // Step 3: Set the validationSchema for formikDeclaration
    });


    //
    const handleModalSubmitBtn = (saveExit: false) => {
        kycDecQuestion.questionAnswer?.map((value: any, index: any) => {
            const questionId = kycDecQuestion.questionAnswer[index]?.id;
            const questionIdNested = `${kycDecQuestion.questionAnswer[index]?.id}-nested`;
            let body: any = {
                investorCode: user.id,
                investorQueriesDto: [
                    {
                        id: questionId,
                        answer: {
                            answerOptions: [
                                {
                                    id: 0,
                                    value: formikDeclaration.values[questionId].toString()
                                }
                            ]
                        },
                        riskProfileCategoryDto: {
                            id: kycDecQuestion.questionAnswer[index]?.category?.id
                        }
                    }
                ]
            };

            if (formikDeclaration?.values[questionIdNested]) {
                let nestedValues: any = [];
                if (questionIdNested === '636c51ca1833d0b8ffb404e4-nested') {
                    nestedValues = formikDeclaration?.values[questionIdNested];
                } else {
                    if (!!formikDeclaration?.values[questionIdNested]) {
                        if (formikDeclaration?.values[questionIdNested]?.length > 0) {
                            const nestedArr = Array.isArray(formikDeclaration?.values[questionIdNested]) ? formikDeclaration?.values[questionIdNested] : [`${formikDeclaration?.values[questionIdNested]}`]
                            nestedValues = nestedArr?.map(
                                (item: any) => {
                                    return {
                                        value: item
                                    };
                                }
                            );
                        } else {
                            nestedValues = formikDeclaration?.values[questionIdNested];
                        }
                    }
                }
                body = {
                    investorCode: user.id,
                    investorQueriesDto: [
                        {
                            id: kycDecQuestion.questionAnswer[index].id,
                            answer: {
                                answerOptions: [
                                    {
                                        id: 0,
                                        value: formikDeclaration?.values[questionId].toString(),
                                        answer: { answerOptions: nestedValues }
                                    }
                                ]
                            },
                            riskProfileCategoryDto: {
                                id: kycDecQuestion.questionAnswer[index]
                                    ?.category?.id
                            }
                        }
                    ]
                };
            }
            if (kycDecQuestion.questionAnswer?.length - 1 === index) {
                dispatch(onPostUserAnswersRiskProfile(body));
                if (saveExit) {
                    push("myprofile?scroll=true")

                } else {
                    setIsOpenModal(false)
                    handleNext()
                }
                // setLoading(true);
                // setTimeout(() => {
                //     setLoading(false);
                //     dispatch(onGetAllUserQuestionAnswers(QuestionAnswerCategoryEnum.CashFund));
                //     dispatch(onGetUserQuestionAnswers(user?.id, QuestionAnswerCategoryEnum.CashFund));
                // }, 500);
            } else {
                dispatch(onPostUserAnswersRiskProfile(body));
            }
        });
    }

    const steps = [
        <UploadCNICWebcam key={1} handlePrevious={handlePrevious} handleNext={handleNext} formik={formikUploadCNICWebcam} handleAPI={handleCnicWebCamAPI} />,
        <UploadFaceWebcam key={2} handlePrevious={handlePrevious} handleNext={handleNext} formik={formikUploadSelfieWebcam} handleAPI={handleSelfieWebCamAPI} />,
        <UploadPOI key={3} handlePrevious={handlePrevious} handleNext={handleNext} formik={formikUploadPOI} />,
        <FatcaAndCrs key={4} handlePrevious={handlePrevious} handleNext={handleNext} formik={fatcaFormik} handleAPI={FatcaApiHandler} />,
        <Declaration key={5} handlePrevious={handlePrevious} handleNext={handleNext} formik={formikDeclaration} handleModalSubmitAPIBtn={handleModalSubmitBtn} />,
        <SuccessScreen key={6} handlePrevious={handlePrevious} handleNext={handleNext} formik={formikDeclaration} />,
    ];



    return (
        <>
            <SubmitModal handleClose={() => setIsOpenModal(false)} open={isOpenModal} handleModalSubmitBtn={handleModalSubmitBtn} />

            <Grid container>
                <Grid item xs={12} p={3} sx={{ ...customCard.mainBoxV2, border: 0 }}>
                    <Typography variant='h1' color={colors.grey[800]}>Upgrade account</Typography>
                </Grid>

                <Box width={"100%"} mt={3} pb={{ md: 6, xl: 3, backgroundColor: "#fff" }}>

                    <Box sx={{ p: { xs: "16px", sm: "30px" } }}>

                        <Container maxWidth="lg" sx={{ px: "0px!important" }}>

                            {/* <Grid container sx={{ border: "1px solid #EAECF0", p: { xs: "24px 16px", md: "30px" }, borderRadius: "12px" }}>
                                {steps[currentStep]}
                            </Grid> */}

                            {steps[currentStep]}

                            {currentStep != 5 && <Box sx={{ mt: "24px", display: "flex", justifyContent: "center" }}>
                                STEP {currentStep + 1}/5
                            </Box>}

                        </Container >

                    </Box>

                </Box>
            </Grid>

            {/* <Container disableGutters maxWidth={"xl"}>

                <TopBarHeading heading={"Upgrade account"} />
                <Divider sx={{ borderColor: "#EAECF0" }} />

                <Box sx={{ p: { xs: "16px", sm: "30px" } }}>

                    <Container maxWidth="lg" sx={{ px: "0px!important" }}>
                        <Grid container sx={{ border: "1px solid #EAECF0", p: { xs: "24px 16px", md: "30px" }, borderRadius: "12px" }}>
                            {steps[currentStep]}
                        </Grid>

                        {currentStep != 3 && <Box sx={{ mt: "24px", display: "flex", justifyContent: "center" }}>
                            STEP {currentStep + 1}/3
                        </Box>}

                    </Container >

                </Box>
            </Container> */}
        </>
    )
}

export default Index