import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { tokens } from "theme";

import { CurrencyFormatterWithDecimal, roundToNearestThousand } from "utils/calculation";
import { componentV1Style } from "Latest/ComponentV1/style/style";
import LoadingBtnCustomBack from "Latest/ComponentV1/LoadingBtnCustomBack";
import InvestModal from "Latest/ModalV2/investModal";
import { useAppSelector } from "hooks/reduxHooks";
import depositIcon from "Latest/Images/depositIcon.png";
import { goalCategory, transactionTableStatusV2 } from "../../../constants";
import Big from 'big.js';
import { useEffect, useRef, useState } from "react";
import MenuUpdateGoals from "Latest/GoalDetail/updateDltComp";
import formatNumberForDisplay from "utils/numberDisplay";

export const CircularProgressBar = ({ margRight, percentage }: any) => {
    // Calculate the radius and circumference of the circle
    const radius = 25;
    const circumference = 2 * Math.PI * radius;

    // Calculate the dash offset to represent the percentage
    const dashOffset = circumference * (1 - percentage / 100);
    const strokeWidth = 3;

    return (
        <div className="circular-progress-bar" style={{ marginRight: margRight }}>
            <svg width={radius * 2} height={radius * 2} viewBox={`0 0 ${radius * 2} ${radius * 2}`} style={{ transform: "rotate(180deg)" }}>
                {/* Gray stroke color */}
                <circle
                    cx={radius}
                    cy={radius}
                    r={radius - strokeWidth / 2} // Adjust for stroke width
                    fill="none"
                    stroke="#E4DCFE" // Gray stroke color
                    strokeWidth={strokeWidth}
                />
                {/* Purple fill color */}
                <circle
                    cx={radius}
                    cy={radius}
                    r={radius - strokeWidth / 2} // Adjust for stroke width
                    fill="none"
                    stroke="#422E84" // Purple fill color
                    strokeWidth={strokeWidth}
                    strokeDasharray={circumference}
                    strokeDashoffset={dashOffset}
                // strokeLinecap="round"
                />
            </svg>
            <div className="progress-percentage">{percentage}%</div>
        </div>
    );
};

export const ApprovedCard = ({ component, data, margin, onClick, title }: any) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const xsScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // const { potDetails } = data;
    const potDetails = data?.potDetails
    const totalAmt = CurrencyFormatterWithDecimal(Number(potDetails?.portfolioAmount || 0), false, "never", true, true)
    const annualizeTWR = CurrencyFormatterWithDecimal(Number(potDetails?.annualizeTWR || 0), false, "never", false)

    const isCashfund = data?.category == "CashFund";
    const isRetirementFund = data?.category == goalCategory.Retirement.toLowerCase()
    const isGIFund = data?.category == goalCategory.GeneralInvest.toLowerCase()

    const Title = isCashfund ? "Cash management account" : `${data?.name}`;

    const completedPortfolioPercentage = Big(data?.potDetails?.portfolioAmount || 0).div(data?.maxLimit || 1).mul(100).round().toNumber();
    const profitPercentage = data?.potDetails?.portfolioAmount !== 0 ? Big(data?.potDetails?.portfolioAmount || 0).sub(data?.potDetails?.gainLossAmount || 0).div(data?.potDetails?.portfolioAmount || 1).mul(100).round().toNumber() : 0;

    const status = transactionTableStatusV2[data?.goalStatus];
    const dltedGoal = data?.goalStatus == "Abandon";

    const tableBodyValue = [
        { title: "Profit (PKR)", value: CurrencyFormatterWithDecimal(formatNumberForDisplay(Number(potDetails?.gainLossAmount || 0)), false, "auto", true, true), minWidth: { xl: 200, md: 150, xs: 150 } },
        potDetails?.annualizeTWR > 5 && { title: "Annualized return", value: annualizeTWR + "%", component: component, minWidth: 200 },
        { title: "Invested (PKR)", value: CurrencyFormatterWithDecimal(Number(potDetails?.totalInvestedAmount || 0), false, "never", true, true) },
    ].filter(Boolean);

    const boxStyles = {
        border: "1px solid #CFCBDF",
        transition: "all 0.3s ease-in-out",
        '&:hover': {
            // border: dltedGoal ? "1px solid #CFCBDF" : "1px solid #40308140",
            // boxShadow: dltedGoal ? "unset" : "0 3px 5px #40308110"
            boxShadow: "0px 3px 1px -1px rgba(67, 47, 135, 0.25)",
            border: "1px solid rgba(67, 47, 135, 0.25)"
        }
    }

    const cardRef = useRef<HTMLDivElement>(null); // Specify the type of the ref
    const [cardRemainingWidth, setCardRemainingWidth] = useState<number | null>(null);

    useEffect(() => {
        const handleResize = () => {
            if (cardRef.current) {
                const width = cardRef.current.offsetWidth;
                setCardRemainingWidth(width - 300);
            }
        };

        // Add event listener on mount
        window.addEventListener('resize', handleResize);

        // Call handleResize to initialize the width
        handleResize();

        // Clean up by removing event listener on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [cardRef]);

    return (
        <Box sx={{ width: "100%", position: "relative", cursor: "pointer" }}>
            <Grid ref={cardRef} item xs={12} sx={boxStyles} p={2} borderRadius={"8px"} onClick={onClick}>

                {(dltedGoal && xsScreen) &&
                    <Box display={"flex"} >
                        <Typography
                            sx={{
                                ...componentV1Style.cfStatusWrapper, backgroundColor: status?.bg, color: status?.color,
                                ...componentV1Style.oneLineTypo,
                            }}
                            mb={1}
                        >
                            {status?.label}
                        </Typography>
                    </Box>
                }

                <Grid item xs={12} mb={"20px"} display={"flex"} justifyContent={"space-between"} alignItems={"flex-start"} >
                    <Box >
                        <Typography
                            variant='h5'
                            color={colors.grey[800]}
                            fontWeight={500}
                            sx={{
                                ...componentV1Style.oneLineTypo,
                                wordBreak: "break-all",
                                paddingRight: 2
                            }}
                        >
                            {Title}
                        </Typography>

                        {(data?.yieldCategory && isGIFund) && <Typography variant='subtitle2' color={"#1D2939"} fontWeight={400} lineHeight={"21px"} display={"flex"} alignItems={"center"} mt={"4px"}><Box sx={{ height: 8, width: 8, borderRadius: 100, backgroundColor: data?.yieldColorCode, ml: "2px", mr: "8px" }} />{data?.yieldCategory}</Typography>}
                    </Box>

                    {(isGIFund && !dltedGoal) &&
                        <Box display={"flex"} alignItems={"center"}>
                            <Box marginRight={"30px"}>
                                <CircularProgressBar percentage={completedPortfolioPercentage} />
                            </Box>

                        </Box>}

                    {(dltedGoal && !xsScreen) && <Typography sx={{
                        ...componentV1Style.cfStatusWrapper, backgroundColor: status?.bg, color: status?.color,
                        ...componentV1Style.oneLineTypo,

                    }} >
                        {status?.label}
                    </Typography >}
                </Grid>

                <Grid container alignItems={"flex-end"} justifyContent={"space-between"} columnGap={"0"} rowGap={"20px"} onClick={onClick}>
                    <Box minWidth={150} flex={1}>
                        <Typography variant='h3' color={colors.purpleAccent[100]} lineHeight={"42px"}>{totalAmt}</Typography>
                        <Typography variant='subtitle2' color={"#1D2939B2"} fontWeight={400} lineHeight={"21px"}>Total value (PKR)</Typography>
                    </Box>

                    <Box minWidth={400} display={{ xl: "flex", md: "contents", xs: "contents" }} justifyContent={"flex-end"} columnGap={{ xl: '15px' }}>
                        {tableBodyValue?.map((el: any, index: any) => {

                            const getTextAlignment = () => {
                                if (potDetails?.annualizeTWR > 5) {
                                    if (index % 2 == 0 && (cardRemainingWidth && (cardRemainingWidth < 235 && cardRemainingWidth > 36))) {
                                        return "right";
                                    } else {
                                        return "left";
                                    }
                                } else {
                                    if (index % 2 == 0 && (cardRemainingWidth && (cardRemainingWidth < 137 && cardRemainingWidth > 36))) {
                                        return "right";
                                    } else {
                                        return "left";
                                    }
                                }
                            }

                            const align = getTextAlignment();


                            return (
                                el && <Box key={el} minWidth={el?.minWidth} textAlign={align}>
                                    <Typography variant='subtitle2' color={colors.grey[800]} lineHeight={"24px"}>{el?.value}</Typography>
                                    <Typography variant='subtitle2' color={"#1D2939B2"} fontWeight={400} lineHeight={"21px"}>{el?.title} {el?.component && el?.component}</Typography>
                                </Box>
                            )
                        })}
                    </Box>

                </Grid>
            </Grid>
            {(isGIFund && !dltedGoal) &&
                <Box sx={{ position: "absolute", top: "25px", right: "0", marginRight: "15px" }}><MenuUpdateGoals goalInfo={data} /></Box>}

        </Box>
    )
}

export const VerificationPendingCard = ({ data, margin }: any) => {
    // console.log('VerificationPendingCard props: ' , data);

    const theme = useTheme();
    const xsScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const status = transactionTableStatusV2["ApprovalPending"];
    const isCashfund = data?.category == "CashFund";
    const isRetirementFund = data?.category.toLowerCase() == goalCategory.Retirement.toLowerCase()
    const isGIFund = data?.category.toLowerCase() == goalCategory.GeneralInvest.toLowerCase()


    const Title = isCashfund ? "Cash management account" : isRetirementFund ? 'Voluntary Pension Scheme (VPS)' : `${data?.name}`;

    const boxStyles = {
        // backgroundColor : "yellow",
        border: "1px solid #CFCBDF",
        cursor: "pointer",
        transition: "all 0.3s ease-in-out",
        '&:hover': {
            // border: dltedGoal ? "1px solid #CFCBDF" : "1px solid #40308140",
            // boxShadow: dltedGoal ? "unset" : "0 3px 5px #40308110"
            boxShadow: "0px 3px 1px -1px rgba(67, 47, 135, 0.25)",
            border: "1px solid rgba(67, 47, 135, 0.25)"
        },
        marginTop: 0
    }

    return (
        <>
            <Grid item xs={12} p={2} borderRadius={"12px"} mt={margin} sx={boxStyles}>

                <Box mb={"20px"}>

                    <Box display={"flex"} flexDirection={{ md: "row", sm: "row", xs: "column-reverse" }} gap={"4px"} alignItems={{ md: "center", sm: "center", xs: "flex-start" }}>

                        <Typography
                            sx={{
                                ...componentV1Style.oneLineTypo,
                                wordBreak: "break-all",
                                paddingRight: 2
                            }}
                            variant='h5' color={"#1D2939B2"} fontWeight={500} flex={1}>{Title}</Typography>

                        <Box width={xsScreen ? "100%" : "auto"} display={"flex"} alignItems={"center"}>
                            <Typography sx={{
                                ...componentV1Style.cfStatusWrapper, backgroundColor: status?.bg, color: status?.color,
                                ...componentV1Style.oneLineTypo
                            }}>
                                {status?.label}
                            </Typography>
                            <Box flex={1} />
                            <MenuUpdateGoals goalInfo={data} />
                        </Box>

                    </Box>

                    {(data?.yieldCategory && (isGIFund)) && <Typography variant='subtitle2' color={"#1D2939B2"} fontWeight={400} lineHeight={"21px"} display={"flex"} alignItems={"center"} mt={"4px"}><Box sx={{ height: 8, width: 8, borderRadius: 100, backgroundColor: data?.yieldColorCode, ml: "2px", mr: "8px" }} /> {data?.yieldCategory}</Typography>}
                </Box>

                <Grid container xs={12} sm={"auto"} sx={{
                    display: "flex", alignItems: "center", justifyContent: "flex-start",
                    rowGap: "20px"
                }}>
                    {data?.category == "GeneralInvestment" && <>
                        <Grid item sx={{ minWidth: { md: "200px", xs: "200px" } }}>
                            <Typography variant='subtitle1' color={"#1D2949B2"} fontWeight={500} lineHeight={"24px"}>{CurrencyFormatterWithDecimal(roundToNearestThousand(Number(data?.maxLimit)), false, "never", true, true)}</Typography>
                            <Typography variant='subtitle2' color={"#1D2939B2"} fontWeight={400} lineHeight={"21px"}>Goal (PKR)</Typography>
                        </Grid>
                    </>}

                    <Grid item>
                        <Typography variant='subtitle1' color={"#1D2939B2"} fontWeight={500} lineHeight={"24px"}>{data?.potDetails?.initialInvestmentAmount ? CurrencyFormatterWithDecimal(data?.potDetails?.initialInvestmentAmount, false, "never", true, true) : "N/A"}</Typography>
                        <Typography variant='subtitle2' color={"#1D2939B2"} fontWeight={400} lineHeight={"21px"}>Initial investment (PKR)</Typography>
                    </Grid>
                </Grid>


            </Grid>
        </>
    )
}

export const DepositPendingCard = ({ data, margin }: any) => {

    const theme = useTheme();
    const xsScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const colors = tokens(theme.palette.mode);
    const { dashboardDetail } = useAppSelector((state: any) => state.dashboard);

    const quickLinksArray = [
        { icon: depositIcon, title: "Deposit money", link: "", modalComp: InvestModal, type: "down" },
    ]

    const customComp = ({ handleOpen }: any) => {

        return (
            <LoadingBtnCustomBack
                btnTitle={"Deposit money"}
                style={{ borderColor: colors.purpleAccent[100], padding: "8px 16px", height: "37px", minWidth: { md: "64px", sm: "64px", xs: "100%" } }}
                handleOnClick={handleOpen}
            />
        )
    }

    const dltedGoal = data?.goalStatus == "Abandon";
    const status = dltedGoal ? transactionTableStatusV2[data?.goalStatus] : transactionTableStatusV2["DepositPending"];

    const isCashfund = data?.category.toLowerCase() == "CashFund".toLowerCase();
    const isRetirementFund = data?.category.toLowerCase() == goalCategory.Retirement.toLowerCase()
    const isGIFund = data?.category.toLowerCase() == goalCategory.GeneralInvest.toLowerCase()
    const Title = isCashfund ? "Cash management account" : isRetirementFund ? 'Voluntary Pension Scheme (VPS)' : `${data?.name}`;

    const boxStyles = {
        border: "1px solid #CFCBDF",
        cursor: "pointer",
        transition: "all 0.3s ease-in-out",
        '&:hover': {
            // border: dltedGoal ? "1px solid #CFCBDF" : "1px solid #40308140",
            // boxShadow: dltedGoal ? "unset" : "0 3px 5px #40308110"
            boxShadow: "0px 3px 1px -1px rgba(67, 47, 135, 0.25)",
            border: "1px solid rgba(67, 47, 135, 0.25)"
        }
    }

    return (
        <>

            <Grid item xs={12} p={2} borderRadius={"12px"} mt={margin} sx={boxStyles}>

                <Box mb={"20px"}>
                    <Box display={"flex"} flexDirection={{ md: "row", sm: "row", xs: "column-reverse" }} gap={"4px"} alignItems={{ md: "center", sm: "center", xs: "flex-start" }}>
                        <Typography
                            sx={{
                                ...componentV1Style.oneLineTypo,
                                wordBreak: "break-all",
                                paddingRight: 2
                            }}
                            variant='h5' color={"#1D2939B2"} fontWeight={500} flex={1}>{Title}</Typography>

                        <Box width={xsScreen ? "100%" : "auto"} display={"flex"} alignItems={"center"}>
                            <Typography sx={{
                                ...componentV1Style.cfStatusWrapper, backgroundColor: status?.bg, color: status?.color,
                                ...componentV1Style.oneLineTypo
                            }}>
                                {status?.label}
                            </Typography>
                            <Box flex={1} />
                            {!dltedGoal && <MenuUpdateGoals goalInfo={data} />}
                        </Box>

                    </Box>

                    {(data?.yieldCategory && isGIFund) && <Typography variant='subtitle2' color={"#1D2939B2"} fontWeight={400} lineHeight={"21px"} display={"flex"} alignItems={"center"} mt={"4px"}><Box sx={{ height: 8, width: 8, borderRadius: 100, backgroundColor: data?.yieldColorCode, ml: "2px", mr: "8px" }} /> {data?.yieldCategory}</Typography>}
                </Box>

                <Grid container alignItems={"flex-end"} justifyContent={"space-between"} gap={"20px"}>

                    <Grid container xs={12} sm={"auto"} sx={{
                        display: "flex", alignItems: "center", justifyContent: "flex-start",
                        rowGap: "20px"
                    }}>
                        {isGIFund && <>
                            <Grid item sx={{ minWidth: { md: "200px", xs: "200px" } }}>
                                <Typography variant='subtitle1' color={"#1D2949B2"} fontWeight={500} lineHeight={"24px"}>{CurrencyFormatterWithDecimal(roundToNearestThousand(Number(data?.maxLimit)), false, "never", true, true)}</Typography>
                                <Typography variant='subtitle2' color={"#1D2939B2"} fontWeight={400} lineHeight={"21px"}>Goal (PKR)</Typography>
                            </Grid>
                        </>}

                        <Grid item>
                            <Typography variant='subtitle1' color={"#1D2939B2"} fontWeight={500} lineHeight={"24px"}>{data?.potDetails?.initialInvestmentAmount ? CurrencyFormatterWithDecimal(data?.potDetails?.initialInvestmentAmount, false, "never", true, true) : "N/A"}</Typography>
                            <Typography variant='subtitle2' color={"#1D2939B2"} fontWeight={400} lineHeight={"21px"}>Initial investment (PKR)</Typography>
                        </Grid>
                    </Grid>

                    {!dltedGoal && quickLinksArray.map((item: any, index: number) => (
                        <Box key={index} width={{ md: "auto", sm: "auto", xs: "100%" }}>
                            <item.modalComp
                                Data={dashboardDetail?.goals[0]}
                                item={item}
                                customComp={customComp}
                                showRenderItem={false}
                            />
                        </Box>
                    ))}

                </Grid>
            </Grid>
        </>
    )
}