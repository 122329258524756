import { useFormik } from 'formik';
import React, { useState } from 'react'
import { onChangeAccountItem, onGetAccountSetup, onPostAddressV1 } from 'redux/actions';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { addressFormSchema, addressInitialValue, mailingAddressFormSchema, mailingAddressInitialValue, permanentAddressFormSchema, permanentAddressInitialValue } from 'jsx/components/WizardFormModal/AccountSetup/validation';
import PermanentField from 'jsx/components/WizardFormModal/AccountSetup/permanentField';
import { Box, Button, Grid, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ModalStyle } from '../Style/modalStyle';
import Loader from 'jsx/components/Shared/Loader';
import { toast } from 'react-toastify';
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1';
import FormikInputField from 'jsx/components/Account_Fields/FormikInputFieldLatest';
import * as Yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input'
import PhoneField from 'jsx/components/Shared/PhoneField';
import { useAppLoading } from 'hooks/appLoading';
import { VerificationMethod } from '../../../constants';
import { handleUserPhEmVerifyModal, handleUserPhEmVerifyModalSpec } from 'redux/reducers/userPhoneEmailVerification';


const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#FFFFFF',
    boxShadow: "0px 8px 8px -4px rgba(16, 24, 40, 0.04), 0px 20px 24px -4px rgba(16, 24, 40, 0.10)",
    p: "24px",
    borderRadius: "8px",
    maxWidth: '100%',
    width: { xs: "95%", sm: "500px", md: "500px" },

    '& img': { height: 'auto', width: 'auto', maxWidth: '100%' },
};
const Index = ({ compType, handleClose, handleOpen, open }: any) => {

    const { userAccountDetails } = useAppSelector(state => state.auth);
    const loading = useAppLoading('auth');


    const customStyles = {
        display: "flex", flexDirection: "column", gap: "24px",
        '& h5': { fontSize: "18px", color: "#101828", lineHeight: "28px" },
        '& .col-md-12 p': { color: "#344054" }
    }
    const dispatch = useAppDispatch();

    const dropdownStyles = {
        width: "245px",
    }

    const emailSchema = Yup.object({
        email: Yup.string().email().required().label('Email Address'),
    });

    const phoneSchema = Yup.object({
        phoneNumber: Yup.string().required('Mobile number is required').test('require', '', function (value) {
            const isValid = isValidPhoneNumber(`+${String(value)}`);
            if (!isValid) {
                return this.createError({
                    message: "Mobile number is Invalid"
                });
            }
            return true;
        }),
    });

    const schemaMap: any = {
        email: emailSchema,
        phoneNumber: phoneSchema,
    };

    const initialValuesMap: any = {
        email: '',
        phoneNumber: '',
    };

    const formik = useFormik({
        initialValues: { ...initialValuesMap },
        onSubmit: values => {
            handleConfirm()
        },
        validationSchema: schemaMap[compType],
    });

    const handleCloseFunction = () => {
        handleClose()
        formik.resetForm()
    }

    const handleConfirm = () => {

        const dataPhoneNumber = {
            "fieldsValues": {
                "ContactNumber": `+${formik.values.phoneNumber}`,
            }
        }

        const dataEmail = {
            "fieldsValues": {
                "Email": formik.values.email,
            }
        }

        const onSuccess = () => {
            toast.success('Update Request sent!', { theme: "colored" });
            handleCloseFunction();
            dispatch(handleUserPhEmVerifyModal(true))
            dispatch(handleUserPhEmVerifyModalSpec(compType == VerificationMethod.phoneNumber ? VerificationMethod.phoneNumber : VerificationMethod.email))
            dispatch(onGetAccountSetup());
        }

        const onFail = (message: any, response: any) => {
            console.log(message, response.status, "OPOOP")
            // toast.error('Invalid OTP', { theme: "colored" });
            return;
        }

        const body = compType == VerificationMethod.phoneNumber ? dataPhoneNumber : dataEmail

        dispatch(onChangeAccountItem(body, userAccountDetails?.userId, onSuccess, onFail))

    }

    const JSXEmail = () => {
        return (
            <>
                <Typography variant='h5' color={"#101828"}>Update Email Address</Typography>

                <Grid item md={12} xs={12}>
                    <FormikInputField
                        formik={formik}
                        FieldTouchInput={true}
                        label="Enter Email"
                        name='email'
                        placeholder="Enter email"
                    />
                </Grid>

                <ButtonRowV1
                    handleBack={handleCloseFunction} handleNext={formik.handleSubmit}
                    loadingNextBtn={loading} btnTitleNext={"Update"}
                    btnTitleBack="Cancel"
                />
            </>
        )
    }

    const JSXPhoneNumber = () => {
        return (
            <>
                <Typography variant='h5' color={"#101828"}>Update Phone Number</Typography>

                <Grid item md={12} xs={12}>
                    <PhoneField
                        required
                        country={'pk'}
                        countryCodeEditable={false}
                        disableCountryCode={false}
                        disableDropdown={false}
                        dropdownStyle={dropdownStyles}
                        enableSearch={true}
                        fieldName='phoneNumber'
                        formik={formik}
                        label='Mobile number'
                        value={formik.values.phoneNumber}
                    />
                </Grid>

                <ButtonRowV1
                    handleBack={handleCloseFunction} handleNext={handleConfirm}
                    loadingNextBtn={loading} btnTitleNext={"Update"}
                    btnTitleBack="Cancel"
                />
            </>
        )
    }

    const renderContent = () => {
        if (compType == VerificationMethod.email) {
            return JSXEmail();
        } else if (compType == VerificationMethod.phoneNumber) {
            return JSXPhoneNumber();
        }
    };


    return (
        <>
            <Modal
                open={open}
                onClose={handleCloseFunction}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ ...modalStyle, ...customStyles }}>
                    {renderContent()}
                </Box>


            </Modal>

        </>
    )
}

export default Index