/* eslint-disable sort-destructure-keys/sort-destructure-keys */
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useEffect } from 'react';
import { onGetAccountSetup, onPutUserAnswers } from 'redux/actions';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import FormikInputField from '../../components/Account_Fields/FormikInputFieldLatest';
import '../../../scss/login/_Login.scss';
import { Container, Grid } from '@mui/material';
import { ibanValidation } from 'validate/Screen/AccountSetupFormValidation';
import { mainContainerV2, smallScreenBtnWrap } from 'Latest/Style/Style';
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1';
import infoIcon from 'Latest/Images/svg/infoIconGray.svg';
import { CASH_FUND_ID } from '../../../constants';
import { useAppLoading } from 'hooks/appLoading';
import FormikRadioField from '../../components/Account_Fields/FormikRadioFieldV2';

const BankDetailsForm = ({ checkAddress, formikVP, goalId, loading, nextStep, previousStep , checkIban}: any) => {
    const dispatch = useAppDispatch();
    const { userAccountDetails } = useAppSelector(state => state.auth);
    const IS_CASH_ACC = goalId == CASH_FUND_ID
    const { dashboardDetail } = useAppSelector((state: any) => state.dashboard);

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));

    const resLoading = useAppLoading('questionAnswer');

    useEffect(() => {
        dispatch(onGetAccountSetup());
    }, []);


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            IBANnumber: userAccountDetails?.iban,
            isRoshanDigitalBankAccount: userAccountDetails?.isRoshanDigitalBankAccount == null ? null : userAccountDetails?.isRoshanDigitalBankAccount ? "Yes" : "No",
        },
        onSubmit: async (values: any) => {
            onSubmit()
        },
        validationSchema: ibanValidation
    });

    const onSubmit = () => {

        const body = {
            name: userAccountDetails?.identityCard?.name,
            fatherOrHusbandName: userAccountDetails?.identityCard?.fatherName,
            dateOfBirth: userAccountDetails?.identityCard?.dateOfBirth,
            identityCardNumber: userAccountDetails?.identityCard?.identityCardNumber,
            identityCardIssuanceDate: userAccountDetails?.identityCard?.dateOfIssue,
            identityCardExpiryDate: userAccountDetails?.identityCard?.dateOfExpiry,
            gender: userAccountDetails?.identityCard?.gender,
            countryOfStay: userAccountDetails?.identityCard?.countryOfStay,
            identityCardLifeTimeExpiry: userAccountDetails?.identityCard?.lifeTimeExpiry,
            accountType: userAccountDetails?.accountType,
            rememberUKN: userAccountDetails?.rememberUKN,
            ukn: userAccountDetails?.ukn,
            motherName: userAccountDetails?.motherName,
            iban: formik?.values?.IBANnumber,
            isRoshanDigitalBankAccount: formik?.values?.isRoshanDigitalBankAccount == "Yes" ? true : false
        };

        const onSuccess = () => {
            nextStep();
        }

        dispatch(onPutUserAnswers(body, onSuccess));
    };

    useEffect(() => {
        let modifiedValue: any = formik.values.IBANnumber || '';

        modifiedValue = modifiedValue.toUpperCase();
        modifiedValue = modifiedValue.replace(/\s/g, '');

        formikVP.setFieldValue('IBANnumber', modifiedValue)
        formik.setFieldValue('IBANnumber', modifiedValue)

    }, [formik.values.IBANnumber])



    const goBack = (e: any) => {
        e.preventDefault();
        if (userAccountDetails?.isAccountSetupComplete && checkAddress == null) {
            previousStep()
        }
        else if (userAccountDetails?.isAccountSetupComplete && userAccountDetails?.permanentAddress?.lines?.length > 0) {
            if (IS_CASH_ACC && dashboardDetail?.status != "New") {
                previousStep(undefined, 5)
            } else {
                previousStep(undefined, 4);
            }
        } else {
            previousStep()
        }
    }

    const goNext = (e: any) => {
        e.preventDefault();
        formik.submitForm();
    }

    const buttonHtml = () => {
        return (
            <ButtonRowV1
                handleNext={goNext} handleBack={goBack} btnTitleNext={"Continue"}
                loadingNextBtn={loading || resLoading}
                disableNext={!!formik?.errors?.IBANnumber || !!formik?.errors?.isRoshanDigitalBankAccount}
            />
        )
    }

    const infoText = (textList: any) => {
        return (
            textList?.map((text: string) => {
                return (
                    <Box key={text} display={"flex"} alignItems={"flex-start"} pt={1}>
                        <img src={infoIcon} alt='info' />
                        <Typography pl={1} color={"rgba(29, 41, 57, 0.70)"} fontSize={{ md: "14px", xs: "14px" }}>
                            <span dangerouslySetInnerHTML={{ __html: text }}></span>
                        </Typography>
                    </Box>
                )
            })

        )
    }


    const roshanDigitalFields = [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: "No" },
    ];


    return (
        <>
            <Container maxWidth={'lg'} disableGutters={smScreen} sx={{ pt: { xs: "24px", md: "40px" } }}>

                <Grid container>

                    <Grid item md={12} xs={12} sx={mainContainerV2}>

                        <Grid item md={12} xs={12}>
                            <Typography variant='h4' color={"#1D2939"} lineHeight={"normal"} textAlign={"center"}>Bank details</Typography>
                        </Grid>

                        <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={"40px"}>
                            <Grid item md={12} xs={12}>
                                <Grid item md={6} xs={12} mb={3}>
                                    <FormikInputField
                                        formik={formik}
                                        FieldTouchInput={true}
                                        label="Your bank account IBAN"
                                        name='IBANnumber'
                                        placeholder="Enter IBAN"
                                    />
                                    {infoText(["Add a bank account that’s in your name.", "For security purposes all cash withdrawals from Mahaana will be made to this bank account."])}
                                </Grid>

                                <Grid item md={6} xs={12} mb={3}>
                                    <FormikRadioField
                                        fieldName='isRoshanDigitalBankAccount'
                                        fields={roshanDigitalFields}
                                        formik={formik}
                                        label='Is this a Roshan Digital bank account (RDA)?'
                                        singleLine={true}
                                    />
                                </Grid>

                            </Grid>

                            {!smScreen && buttonHtml()}
                        </Box>
                    </Grid>


                    {smScreen && <Box sx={smallScreenBtnWrap}>
                        {buttonHtml()}
                    </Box>}


                </Grid>

            </Container>

        </>
    );
}

export default BankDetailsForm;