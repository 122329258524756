import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Alert, AlertTitle, Container, Drawer, Grid, IconButton, useTheme } from '@mui/material';
import { investStyle } from './style';
import { CurrencyFormatter, CurrencyFormatterWithDecimal } from 'utils/calculation';
import { getServiceFee, goalFlowIdAction, goalFlowPathAction, goalIdAction } from 'redux/actions';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from 'hooks/reduxHooks';
import { links } from 'static/links';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Flag from "../../Latest/Images/flag.png"
import InputField from 'jsx/components/Shared/V1/FormikInputFieldLatestV1';
import { useFormik } from 'formik';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { tokens } from 'theme';
import BankDetails from 'Latest/MyProfile/BankDetails/bankDetailGrid';
import CustomButtonNeutral from 'Latest/ComponentV1/CustomMakeStyle/CustomButtonNeutral';
import useStyles from 'Latest/ComponentV1/CustomMakeStyle/CustomButtonNeutral';
import RenderItem from './renderItem';
import { CASH_FUND_ID, KycStatus, FlowPath, GENERAL_INVEST_ID, goalCategory, intialInvestmentMaxLimit } from '../../constants';
import arrowGreen from "Latest/Images/svg/greenBgWithArrow.svg"
// import arrowGreen from "Latest/Images/svg/arrowGreen.svg"
import FormikReactSelect from "jsx/components/Shared/V1/FormikReactSelectV1"
import { onGetInvestProductsValue } from 'redux/reducers/sharedStateData';
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1';
import IconDanger from 'Latest/Images/svg/IconDanger2.svg';
import useStylesWarningAlert from 'Latest/ComponentV1/CustomMakeStyle/customWarningAlert';


type Anchor = 'top' | 'left' | 'bottom' | 'right';
type ErrorMessage = string | JSX.Element;
type CustomValidationError = {
  message: any;
};

export default function InvestModal({ Data, anchor = "right", customComp, item, showRenderItem = true }: any) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const classes = useStyles();

  const [selectedbank, setSelectedbank] = React.useState()
  const [selectionProducts, setSelectionProducts] = React.useState([])

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { dashboardDetail } = useAppSelector((state: any) => state.dashboard);
  const { userAccountDetails } = useAppSelector(state => state.auth);
  const { transactionDetail } = useAppSelector(state => state.sharedData);
  const classess = useStylesWarningAlert()

  const comment: any = userAccountDetails?.kyc?.comment

  const transactionDetailCheck = transactionDetail?.length >= 1

  const { push } = useHistory();
  const dispatch = useDispatch();


  React.useEffect(() => {
    dispatch(getServiceFee());
  }, [])

  const maxLimit = CurrencyFormatterWithDecimal(intialInvestmentMaxLimit, true, "never")

  React.useEffect(() => {
    if (dashboardDetail?.goals.length > 0) {
      const filteredGoals = dashboardDetail?.goals?.filter((item: any) => item?.goalStatus != "Abandon")
      const filterKey = filteredGoals.map((item: any, ind: number) => (
        {
          "category": item.category,
          "name": item.name,
          "label": item.category.toLowerCase() == goalCategory.CashFund.toLowerCase() ? "Save+" : item.category.toLowerCase() == goalCategory.GeneralInvest.toLowerCase() ? `Invest: ${item.name}` : `Mahaana Retirement`,
          // "label": item.name,
          "value": item.idGoal
        }))
      setSelectionProducts(filterKey)
    }
  }, [dashboardDetail])

  const handleSelectedBank = (item: any) => {
    setSelectedbank(item)
  }

  const handleRemainingLimit = (categ: any) => {
    if (categ.toLowerCase() == goalCategory.CashFund.toLowerCase()) {
      return dashboardDetail?.summary?.cashFundRemainingLimitAmount > 0 ? dashboardDetail?.summary?.cashFundRemainingLimitAmount : 0
    } else if (categ.toLowerCase() == goalCategory.GeneralInvest.toLowerCase()) {
      return dashboardDetail?.summary?.generalInvestRemainingLimitAmount > 0 ? dashboardDetail?.summary?.generalInvestRemainingLimitAmount : 0
    } else if (categ.toLowerCase() == goalCategory.Retirement.toLowerCase()) {
      return dashboardDetail?.summary?.voluntaryPensionRemainingLimitAmount > 0 ? dashboardDetail?.summary?.voluntaryPensionRemainingLimitAmount : 0
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { investAmount: '', selectedProduct: "", selectedProductName: "" },
    onSubmit: values => {

      const filterProduct = dashboardDetail?.goals.filter((item: any, index: number) => item.idGoal == values.selectedProduct);
      const categoryId = filterProduct[0]?.category?.toLowerCase() == goalCategory.GeneralInvest?.toLowerCase() ? GENERAL_INVEST_ID : CASH_FUND_ID

      dispatch(goalIdAction(filterProduct[0]?.idGoal))                       //save Goal id i.e cashfundId , GI id  
      dispatch(goalFlowIdAction(categoryId))                           //save Goal category i.e cashfund == 100 
      dispatch(goalFlowPathAction(filterProduct[0]?.category?.toLowerCase() == goalCategory.GeneralInvest?.toLowerCase() ? FlowPath.Invest : FlowPath.Cashfund))             //save Path i.e dashboard , wizard 

      console.log(filterProduct, "filterProduct", categoryId)

      const params = { key: 'Sell', amount: values, idCashFundInvestment: filterProduct[0]?.idGoal, category: filterProduct[0]?.category };

      if (!userAccountDetails?.motherName && categoryId == GENERAL_INVEST_ID) {
        push(links.TermsCondition, params);
      } else {
        push(links.PayNow, params);
      }
    },

    validationSchema: Yup.object({
      selectedProduct: Yup.string().required("Product is required").nullable(),
      investAmount: Yup.number()
        .test('require', '', function (value, context) {
          let errorMessage: ErrorMessage = '';

          const transactionDetailCheckCf = transactionDetail?.filter((item: any) => item?.category?.toLowerCase() == goalCategory?.CashFund?.toLowerCase()).length >= 1
          const filterProduct = dashboardDetail?.goals.filter((item: any, index: number) => item.idGoal == context?.parent?.selectedProduct);
          const filterCategoriesObj: any = selectionProducts.filter((item: any) => item?.value == context?.parent?.selectedProduct)

          const remainingLimitAmount = handleRemainingLimit(filterCategoriesObj[0]?.category)
          const maxLimit = filterProduct[0]?.maxLimit;


          // amt is required
          if (Number(value) == 0) {
            return this.createError({ message: "Amount is required" });
          }
          //
          // amt min validation for CF and GI is required
          if (value && filterCategoriesObj[0].category.toLowerCase() == goalCategory.GeneralInvest.toLowerCase() && value < 10000) {
            return this.createError({ message: "Amount should be greater than or equal to PKR 10,000.00" });
          } else if (value && filterCategoriesObj[0].category.toLowerCase() == goalCategory.CashFund.toLowerCase() && value < (transactionDetailCheckCf ? 500 : 1000)) {
            return this.createError({ message: `Amount should be greater than or equal to PKR ${transactionDetailCheckCf ? "500.00" : "1000.00"}` })
          } else if (value && filterCategoriesObj[0].category.toLowerCase() == goalCategory.Retirement.toLowerCase() && value < 10000) {
            return this.createError({ message: "Amount should be greater than or equal to PKR 10,000.00" });
          }
          //

          // amt max limit validation
          if (value && value > maxLimit && userAccountDetails?.kyc?.status != 'ApprovalSucceeded') {
            if (userAccountDetails?.kyc?.status === KycStatus.ApprovalHalted) {
              errorMessage = handleFatcaErrorBox()
            } else {
              errorMessage = handleErrorBox(maxLimit, remainingLimitAmount);
            }
          }

          if (value && value > Number(remainingLimitAmount) && userAccountDetails?.kyc?.status != 'ApprovalSucceeded') {
            if (userAccountDetails?.kyc?.status === KycStatus.ApprovalHalted) {
              errorMessage = handleFatcaErrorBox()
            } else {
              errorMessage = handleErrorBox(maxLimit, remainingLimitAmount);
            }

          }



          if (userAccountDetails?.kyc?.status == 'ApprovalSucceeded') {
            if (filterCategoriesObj[0]?.category?.toLowerCase() == 'generalinvestment' && value && value > maxLimit) {
              return this.createError({ message: `As of now users can’t invest more than ${maxLimit} in Mahaana Invest goals.` });
            }
          }
          // Create a custom error object with the desired properties
          const customError: CustomValidationError = {
            message: errorMessage,
          };

          if (customError.message == "") {
            return true
          } else {
            // Explicitly cast the error object to the CustomValidationError type
            return (this.createError as (params: CustomValidationError) => any)(customError);
          }

        })
        // .min(transactionDetailCheck ? 500 : 1000, `Amount should be greater than or equal to PKR ${transactionDetailCheck ? "500.00" : "1000.00"}`)
        .required('Amount is required')
        .nullable(),


    }),
  });

  const onSubmit = (params: any) => {
    console.log(params, "PLOKIJ-Params")

    const { amount } = params
    const onSuccess = (message: string, response: any) => {
      console.log(message, "PLOKIJ")
      const updatedParam = { ...params, productsSegregation: message }
      push(links.PayNow, updatedParam);
    }

    dispatch(onGetInvestProductsValue("productsSegregation", amount.investAmount, params.idCashFundInvestment, onSuccess))

  }

  const preSelectAmount = [50000, 100000, 150000, 200000]

  const [state, setState] = React.useState<any>({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event?.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }
        setState({ ...state, [anchor]: open });
      };

  const handleErrorBox = (maxLimit: any, remainingLimitAmount: any) => {
    return (
      <Box sx={{ mt: "12px" }}>
        <Alert severity="error" color='error' sx={{ border: "1px solid #541313", borderRadius: "8px", backgroundColor: "#FEE9E8" }} icon={false}>
          <Typography variant='body2' color="#541313" fontWeight={"600"} display={"flex"} gap={"8px"} mb={"8px"}>
            <img src={IconDanger} />
            Account limit exceeded
          </Typography>

          <Typography variant='body2' color="#541313" letterSpacing={"0.17px"}>
            You can only invest a total of <strong>{CurrencyFormatterWithDecimal(maxLimit, true, 'never', false)}</strong>. Remaining investment amount is <strong>{CurrencyFormatterWithDecimal(remainingLimitAmount, true, "never", false)}</strong>. Upgrade your account to remove limits.  </Typography>

          <Button
            sx={{
              borderRadius: "100px", height: "37px", textTransform: "unset", fontWeight: 500,
              padding: "8px 16px", border: "1px solid #541313",
              backgroundColor: "#fff", color: "#541313",
              fontSize: "14px", lineHeight: "21px", mt: 1
            }}
            onClick={() => push("/myprofile?scroll=true")}
          >
            Upgrade now
          </Button>


          {/* <Button disableElevation size="small" variant='contained' color="primary" sx={{ mt: 1 }} onClick={() => push("/myprofile?scroll=true")}>Upgrade Now</Button> */}
        </Alert>
      </Box>
    )
  }

  const handleFatcaErrorBox = () => {
    return (
      <Box sx={{ mt: "12px", width: "100%" }}>
        <Alert severity="warning" className={classess.customAlert} >
          <AlertTitle className={classess.customAlertTitle}>Some documents missing in your 2nd tier KYC </AlertTitle>
          {comment != null && <Typography className={classess.customText}>{comment}</Typography>}
          <Button
            sx={{
              borderRadius: "100px", height: "37px", textTransform: "unset", fontWeight: 500,
              padding: "8px 16px", border: "1px solid #541313",
              backgroundColor: "#fff", color: "#541313",
              fontSize: "14px", lineHeight: "21px", mt: 1
            }}
            onClick={() => push("/myprofile?scroll=true")}
          >
            Update Now
          </Button>
        </Alert>
      </Box>
    )
  }

  const handleCloseDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    toggleDrawer(anchor, false)(event)
    formik.resetForm()
  }

  console.log(formik,"formik")
  return (
    <div>
      {customComp && customComp({ handleOpen: toggleDrawer(anchor, true) })}
      {showRenderItem && <RenderItem handleOpen={toggleDrawer(anchor, true)} item={item} />}

      <Drawer
        anchor={anchor}
        open={state[anchor]}
        onClose={handleCloseDrawer}
        sx={{ zIndex: "1201" }}
        PaperProps={{
          sx: { width: { xs: "100%", sm: "80%", md: "448px" }, borderRadius: "8px 0 8px 0" },
        }}
      >
        <Box
          role="presentation"
        // onClick={toggleDrawer(anchor, false)}
        // onKeyDown={toggleDrawer(anchor, false)}
        >
          <Container disableGutters sx={{ px: "24px" }}>
            <Box sx={{ position: "absolute", right: 12, top: 20 }}>
              <IconButton aria-label="upload picture" component="label" onClick={handleCloseDrawer}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Grid container >

              <Grid item xs={12}  >

                <Box sx={{ py: "24px", display: "flex", alignItems: "center", gap: "20px" }}>
                  <img src={arrowGreen} />
                  <Typography variant='h4' color={"#344054"}>Deposit money</Typography>
                </Box>

              </Grid>

              <Grid item xs={12} >
                <FormikReactSelect
                  required
                  disabled={false}
                  fieldName='selectedProduct'
                  fields={selectionProducts}
                  formik={formik}
                  label='Select product'
                  onChange={(val, option) => {
                    formik.setFieldValue('selectedProductName', option.name);
                    formik.setFieldValue('selectedProduct', option.value);
                  }}
                  placeholder='Select'
                />
              </Grid>

            </Grid>

            <Grid item xs={12} sx={{ mt: "24px", }}>
              <Box >
                <InputField
                  // FieldTouchInput={true}
                  validateOnType={true}
                  formik={formik}
                  label={"Deposit amount"}
                  maxLength={18}
                  name={"investAmount"}
                  placeholder='Type Amount...'
                  thousandSeparator={true}
                  type={"number"}
                  inputEnd="PKR"
                  disabled={!formik.values.selectedProduct}
                />
              </Box>
            </Grid>


            <Grid item xs={12} sx={{ my: "32px" }}>
              {/* <Box sx={{ width: "50%" }}>
                <Button disableElevation fullWidth size={"large"} variant='outlined' className={classes.neutralButton} onClick={handleCloseDrawer}>Cancel</Button>
              </Box>
              <Box sx={{ width: "50%" }}>
                <Button disableElevation fullWidth size={"large"} variant='contained' color="secondary" onClick={() => formik.submitForm()}>Continue</Button>
              </Box> */}
              <ButtonRowV1
                handleBack={handleCloseDrawer}
                handleNext={() => formik.submitForm()}
                btnTitleBack="Close"
                btnTitleNext="Continue"
                checkFreezAcc={true}
              />
            </Grid>



          </Container>
        </Box>

      </Drawer>

    </div>
  );
}

