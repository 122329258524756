import React, { FormEvent, useEffect, useState } from 'react'
import { Box, Grid, Typography, Modal, useMediaQuery, IconButton } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useTheme } from '@mui/material/styles';
import { AuthMsgs, VerificationMethod } from 'constants/index';
import { formatPhoneNumber } from 'utils/formatPhoneNumber';
import { IdentityVerification, onChangeAccountItem, onGetAccountSetup, onLogout, resendSmsCode } from 'redux/actions';
import Stack from '@mui/material/Stack';
import OTPInput from 'jsx/pages/OtpInput';
import { authStyles } from 'Latest/Style/Style';
import LoadingBtnCustom from 'Latest/ComponentV1/LoadingBtnCustom';
import CloseIcon from '@mui/icons-material/Close';
import { handleUserPhEmVerifyModal, handleUserPhEmVerifyModalSpec } from 'redux/reducers/userPhoneEmailVerification';
import { toast } from 'react-toastify';
import { onGetNotifications } from 'redux/reducers/sharedStateData';


const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#FFFFFF',
    p: "24px",
    borderRadius: "8px",
    maxWidth: '100%',
    width: { xs: "95%", sm: "500px", md: "435px" },
    border: "0.5px solid #D0D5DD40",
    boxShadow: "0px 1px 4px 0px #D0D5DD40",
    padding: "20px 40px",

    '& .otpContainer': {
        gap: '8px',
        display: 'flex',
        flexWrap: 'wrap'
    },

    '& #otp-field input': {
        m: '0 !important',
        width: "50.41px !important",
        height: "57px !important",
        padding: "10px 14px",
        borderRadius: "8px !important",
        border: "1px solid #D0D5DD !important",
        color: '#422E84',
        fontSize: '1rem',
        lineHeight: '24px',
        fontWeight: 500,

        '&::placeholder': {
            color: '#D0D5DD',
        }
    }
};


const UserVerificationModal = () => {

    const { isAuth, isUserVerified, userAccountDetails } = useAppSelector(state => state.auth);
    const { modalSpec, openModal } = useAppSelector(state => state.userPhoneEmailVerifyModal)

    const dispatch = useAppDispatch();

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const defaultTimeLimit = 30;
    const userId = userAccountDetails?.userId;

    const [showModal, setShowModal] = useState(false);
    const [mobOtp, setMobOtp] = useState('');
    const [emailOtp, setEmailOtp] = useState('');
    const [isEmptyFields, setIsEmptyFields] = useState(true);

    const [disableOTP, setDisableOTP] = useState(false);
    const [mobValidateWTP, setMobValidateWTP] = useState<string>('');
    const [counter, setCounter] = useState(defaultTimeLimit); // 1 hour in seconds
    const [isFlag, setIsFlag] = useState(false);


    const [isVerifiedMobOtp, setIsVerifiedMobOtp] = useState(false);
    const [isVerifiedEmailOtp, setIsVerifiedEmailOtp] = useState(false);


    const hours = Math.floor(counter / 3600);
    const minutes = Math.floor((counter % 3600) / 60);
    const seconds = counter % 60;
    const displayTime = `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

    const updateEmail = userAccountDetails?.notifications?.Email
    const oldEmail = userAccountDetails?.email

    const updatePhoneNo = userAccountDetails?.notifications?.PhoneNo
    const oldPhoneNo = userAccountDetails?.contactNumber

    const handleClose = () => {
        dispatch(handleUserPhEmVerifyModal(false))
        dispatch(handleUserPhEmVerifyModalSpec(''))
        setIsVerifiedMobOtp(false)
    }


    useEffect(() => {
        let timerId: any;
        if (counter > 0) {
            timerId = setTimeout(() => setCounter(counter - 1), 1000);
        }

        if (counter === 0) {
            clearInterval(timerId);
            setIsFlag(false);
        }

        return () => clearInterval(timerId);
    }, [counter, isFlag]);

    // useEffect(() => {
    //     dispatch(onGetAccountSetup());
    // }, [])

    const OTPSpec = {
        phoneno: "phoneno",
        email: 'email'
    }

    const messageMethodIsEmail = modalSpec == VerificationMethod.email

    const handleSubmit1stOtp = (e: FormEvent) => {
        e.preventDefault();


        const data = {
            userId: userId,
            spec: messageMethodIsEmail ? OTPSpec.email : OTPSpec.phoneno,
            otp: mobOtp,
        }

        const onSuccess = () => {
            // setIsVerifiedMobOtp(true);
            // setCounter(defaultTimeLimit);
            // setIsFlag(true);
            // setMobOtp('');
            // setIsEmptyFields(true)

            // setTimeout(() => {
            //     setIsEmptyFields(true);
            // }, 100);
            handleSubmit2ndOtp()
        }

        const onFail = (message: any, response: any) => {
            console.log(message, response.status, "OPOOP")
            toast.error('Invalid OTP', { theme: "colored" });
            return;
        }

        (mobOtp.length === 6 && emailOtp.length === 6) && dispatch(IdentityVerification(data, onSuccess, onFail, 'identityVerificationApiName'))

    };


    const handleSubmit2ndOtp = () => {
        // e.preventDefault();
        const data = {
            userId: userId,
            spec: messageMethodIsEmail ? OTPSpec.email : OTPSpec.phoneno,
            otp: emailOtp,
        }

        const onSuccess = () => {
            // setIsVerifiedMobOtp(true);
            // setCounter(defaultTimeLimit);
            // setIsFlag(true);
            // setMobOtp('');
            // setIsEmptyFields(true)
            handleClose()
            toast.success('Verified Successfully', { theme: "colored" });
            dispatch(onGetNotifications())
            dispatch(onGetAccountSetup());
            return;
        }

        const onFail = (message: any, response: any) => {
            console.log(message, response.status, "OPOOP")
            toast.error('Invalid OTP', { theme: "colored" });
            return;
        }

        emailOtp.length === 6 && dispatch(IdentityVerification(data, onSuccess, onFail, 'identityVerificationApiName'))
    };


    const handleResendOTP = (messageMethod: string) => {

        const contactNumber = updatePhoneNo
        const Email = updateEmail

        const dataPhoneNumber = {
            "fieldsValues": {
                "ContactNumber": `${contactNumber}`,
            }
        }

        const dataEmail = {
            "fieldsValues": {
                "Email": Email,
            }
        }

        const onSuccess = () => {
            toast.success('OTP have been resent successfully!', { theme: "colored" });
        }

        const onFail = (message: any, response: any) => {
            console.log(message, response.status, "OPOOP")
            // toast.error('Invalid OTP', { theme: "colored" });
            return;
        }

        const body = messageMethod == VerificationMethod.email ? dataEmail : dataPhoneNumber

        dispatch(onChangeAccountItem(body, userAccountDetails?.userId, onSuccess, onFail))

    };

    const Timer = () => {
        counter === 0 && setCounter(defaultTimeLimit); // Reset to 1 hour when it reaches 0
        setIsFlag(true);
    };

    const verifyMobileNumber = (mobNum: any) => {
        if (mobNum.startsWith('+92') || mobNum.startsWith('92')) {
            return true
        } else {
            return false
        }
    }



    return (
        <div>
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            // sx={{
            //     '& .MuiBackdrop-root': {
            //         bgcolor: '#f8fafb'
            //     }
            // }}
            >
                <Box sx={{ ...modalStyle }}>

                    <Box>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant='h1' color='#1D2939' lineHeight={'36px'} fontSize={"1.4em"}>Verify Updated {modalSpec == VerificationMethod.email ? "Email" : "Number"} </Typography>
                            <IconButton size='small' onClick={handleClose} sx={{}}>
                                <CloseIcon fontSize='small' />
                            </IconButton>
                        </Box>
                        <Typography variant='body2' color={"rgba(29, 41, 57, 0.70)"}>Enter the code from SMS & Email we just sent you.</Typography>

                    </Box>


                    <Box className={"otpForm"} sx={{ mt: "24px", p: 0 }}>
                        <Box className='Form_Secondary' id='otp-field'>
                            <Typography variant='body2' color={'#1D2939'}>Enter your 6 digit sms code({`${modalSpec == VerificationMethod.email ? oldPhoneNo : updatePhoneNo}`})</Typography>

                            <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ marginTop: '6px', '> div': { flexWrap: 'wrap' } }}>
                                <OTPInput
                                    autoFocus
                                    isNumberInput
                                    length={6}
                                    className="otpContainer"
                                    inputClassName="otpInput"
                                    onChangeOTP={setMobOtp}
                                    isEmptyFields={isEmptyFields}
                                // disabled={loading}
                                />
                            </Stack>

                        </Box>
                    </Box>

                    <Box className={"otpForm"} sx={{ mt: "24px", p: 0 }}>
                        <Box className='Form_Secondary' id='otp-field'>
                            <Typography variant='body2' color={'#1D2939'}>Enter your 6 digit email code ({`${modalSpec == VerificationMethod.email ? updateEmail : oldEmail}`})</Typography>

                            <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ marginTop: '6px', '> div': { flexWrap: 'wrap' } }}>
                                <OTPInput
                                    autoFocus
                                    isNumberInput
                                    length={6}
                                    className="otpContainer"
                                    inputClassName="otpInput"
                                    onChangeOTP={setEmailOtp}
                                    isEmptyFields={isEmptyFields}
                                // disabled={loading}
                                />
                            </Stack>


                            <Stack direction={{ xs: 'row', sm: 'row', md: "row" }} spacing={3} style={{ marginTop: '40px' }} >
                                <Typography sx={{ justifyContent: 'start', width: 'auto', color: '#899CA8' }} display={"flex"} flexWrap={"wrap"}>
                                    {counter && counter > 0 ?
                                        <>
                                            <Typography variant='body2' color={"rgba(29, 41, 57, 0.70) !important"} fontWeight={400}>You can resend the code in</Typography>
                                            <Typography sx={authStyles.counter} variant='body2' color={"#49BDD8 !important"} fontWeight={400}>
                                                {displayTime}
                                            </Typography>
                                        </>
                                        :
                                        <>
                                            <Typography variant='body2' color={"rgba(29, 41, 57, 0.70) !important"} fontWeight={400}>Didn’t receive the OTP?
                                                <a className='ResendOtp' onClick={() => { Timer(); handleResendOTP(modalSpec == VerificationMethod.email ? OTPSpec.email : OTPSpec.phoneno); }} style={{ color: "#49BDD8", fontWeight: 500 }} >
                                                    {"Resend"}
                                                </a>
                                            </Typography>
                                        </>
                                    }
                                </Typography>
                            </Stack>

                            <Box sx={{ mt: "8px" }}>
                                <LoadingBtnCustom btnTitle="Verify OTP" size={"large"} fullWidth={true} handleOnClick={handleSubmit1stOtp} />
                            </Box>

                        </Box>
                    </Box>




                </Box>
            </Modal>
        </div >
    )
}


export default UserVerificationModal;