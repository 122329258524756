import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import HeaderSec from './component/headerSec'
import AssesssmentCard from './component/AssesssmentCard'
import PortfolioCard from './component/PortfolioRecommendCard'
import ProjectionCardCom from './component/projectionCard'
import PortfolioChartCard from './component/portfolioChartCard'
import Faqs from './component/faqs'
import { useAppSelector } from 'hooks/reduxHooks'
import CustomModal from "Latest/MyProfile/ModalV2/confirmationModal";
import { ModalStyle } from 'Latest/MyProfile/Style/modalStyle'
import warningIcon from "Latest/Images/svg/warningIcon1.svg"
import ButtonRowV1 from 'Latest/ComponentV1/ButtonRowV1'
import FlowAmtLimitModal from 'Latest/ModalV1/flowAmtLimitModal'
import { CASH_FUND_ID, intialInvestmentMaxLimit, RETIREMENT_ID } from '../../../../constants'
import { useHistory } from 'react-router-dom'
import { CurrencyFormatterWithDecimal } from 'utils/calculation'

const Index = ({
    RedirectLink, checkAddress, checkIban, checkMotherName, checkNic,
    formik,
    goalId,
    handleInterestCompute,
    initialAmount,
    interestData,
    isEmployer,
    loading,
    monthlySaving,
    nextStep,
    pendingRiskProfileQuestion,
    planType,
    previousStep,
    retirementAmount,
    setShowUpdateRiskProfile,
    submitGoal
}: any) => {

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));


    const [upgradeAcc, setUpgradeAcc] = useState(false);

    const [open, setOpen] = useState(false);
    const [openErrModal, setErrModal] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleOpenErrModal = () => setErrModal(true);
    const handleCloseErrModal = () => setErrModal(false);

    const [selectedPorfolio, setSelectedPorfolio] = useState({})

    const { userAccountDetails } = useAppSelector(state => state.auth);
    const { dashboardDetail } = useAppSelector((state: any) => state.dashboard);
    const { push } = useHistory();

    const IS_CASH_ACC = goalId == CASH_FUND_ID
    const IS_GI_ACC = goalId < CASH_FUND_ID
    const IS_Retirement_ACC = goalId == RETIREMENT_ID


    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    const handlePressNext = () => {
        submitGoal();

        if (IS_GI_ACC) {
            if (!checkNic) {
                nextStep(undefined, 1);
                return;
            } else if (!checkAddress) {
                nextStep(undefined, 3);
                return;
            } else if (!checkMotherName) {
                nextStep(undefined, 4);
                return;
            } else {
                nextStep(undefined, 6);
                return;
            }
        } else if (IS_Retirement_ACC) {
            if (!checkNic) {
                nextStep(undefined, 1);
                return;
            } else if (!checkAddress) {
                nextStep(undefined, 3);
                return;
            } else if (!checkIban) {
                nextStep(undefined, 4);
                return;
            } else {
                nextStep(undefined, 5);
                return;
            }

        }


        // if (userAccountDetails?.motherName != null) {
        //     nextStep(undefined, 6);
        // }
        // else if (userAccountDetails?.permanentAddress?.lines?.length > 0) {
        //     nextStep(undefined, 4);
        // }
        // else if (userAccountDetails?.isAccountSetupComplete && checkNic) {
        //     nextStep(undefined, 3);
        // } else {
        //     nextStep();
        // }
    }
    const handlePressGoBack = () => {
        formik.setFieldValue("selectedPorfolio", null)
        RedirectLink == "dashboard" ? previousStep(undefined, 2) : previousStep(undefined, 1)
    }

    const portfolioConfirmationContent = () => {
        return (
            <>
                <Typography variant="h6" color={"#101828"}>Are you sure you wish to continue?</Typography>
                <Typography variant="body2" color={"#475467"}>You’ve chosen a portfolio that may not be suitable as per your risk appetite.</Typography>
            </>
        )
    }

    const amtLimitContent = () => {
        return (
            <>
                <Typography variant="h6" >Account limit reached</Typography>
                <Typography variant="body2" >You can only invest up to {CurrencyFormatterWithDecimal(intialInvestmentMaxLimit, true, "never")}. Your remaining limit is {CurrencyFormatterWithDecimal(dashboardDetail?.summary?.remainingLimitAmount || 0, true, "never", false)}. To invest more, please upgrade your account.</Typography>
            </>
        )
    }

    const handleConfirm = () => {
        if (upgradeAcc) {
            push("/myprofile?scroll=true")
        } else {
            handlePressNext()
        }

    }

    const { personalizeScore } = useAppSelector(state => state.sharedData)

    useEffect(() => {
        if (formik?.values?.selectedPorfolio == null) {
            setSelectedPorfolio(formik?.values?.recommendedPorfolio)
        } else {
            setSelectedPorfolio(formik?.values?.selectedPorfolio)
        }
    }, [formik?.values?.selectedPorfolio, personalizeScore, formik?.values?.recommendedPorfolio,])

    const handleNextBtn = () => {
        if (formik?.values?.initial_amount > dashboardDetail?.summary?.remainingLimitAmount && userAccountDetails?.kyc?.status != "ApprovalSucceeded") {
            setUpgradeAcc(true)
            handleOpen();
            return;
        }
        if (formik.errors.desired_retirement_age || formik?.errors?.initial_amount || formik?.errors?.monthly_saving) {
            if (formik?.values?.initial_amount > intialInvestmentMaxLimit && userAccountDetails?.kyc?.status != "ApprovalSucceeded") {
                handleOpenErrModal()
                return;
            }
            return;
        }
        else if (formik?.values?.selectedPorfolio == null || formik?.values?.selectedPorfolio?.profileName == formik?.values?.recommendedPorfolio?.profileName) {
            handlePressNext();
        } else {
            setUpgradeAcc(false)
            handleOpen();
        }
    }

    const [colorGraph, setColorGraph] = useState("")

    const buttonHtml = () => {
        return (
            <ButtonRowV1 handleBack={handlePressGoBack} handleNext={handleNextBtn} btnTitleNext="Continue" />
        )
    }

    return (
        <>
            <Box style={{ backgroundColor: '#f9fafb' }}>
                <CustomModal
                    handleOpen={handleOpen}
                    open={open}
                    handleClose={handleClose}
                    handleConfirm={handleConfirm}
                    Content={upgradeAcc ? amtLimitContent : portfolioConfirmationContent}
                    loadingConfirmBtn={loading}
                    confirmBtnVariant={"secondary"}
                    btnTitle={upgradeAcc ? "Upgrade account" : "continue"}
                />

                <FlowAmtLimitModal handleClose={handleCloseErrModal} handleModalSubmitBtn={handleCloseErrModal} open={openErrModal} />

                <HeaderSec handlePressGoBack={handlePressGoBack} handlePressNext={handleNextBtn} formik={formik} smScreen={smScreen} IS_GI_ACC={IS_GI_ACC} IS_Retirement_ACC={IS_Retirement_ACC} />
                <Container maxWidth={'xl'} disableGutters={smScreen} >

                    <Grid container sx={{ boxShadow: "0px 1px 4px 0px rgba(208, 213, 221, 0.25)", background: "#ffff", border: "1px solid rgba(208, 213, 221, 0.25)", borderRadius: { xs: 0, md: "8px" }, mt: { xs: "24px", md: "40px" } }}>
                        <AssesssmentCard colorGraph={colorGraph} setColorGraph={setColorGraph} setShowUpdateRiskProfile={setShowUpdateRiskProfile} previousStep={previousStep} formik={formik} RedirectLink={RedirectLink} selectedPorfolio={selectedPorfolio} />
                    </Grid>


                    <Grid container sx={{ boxShadow: "0px 1px 4px 0px rgba(208, 213, 221, 0.25)", background: "#ffff", border: "1px solid rgba(208, 213, 221, 0.25)", borderRadius: "8px", mt: { xs: "24px", md: "40px" } }}>
                        <ProjectionCardCom
                            formik={formik}
                            initialAmount={initialAmount}
                            monthlySaving={monthlySaving}
                            retirementAmount={retirementAmount}
                            planType={planType}
                            handleInterestCompute={handleInterestCompute}
                            isEmployer={isEmployer}
                            interestData={interestData}
                            selectedPorfolio={selectedPorfolio}
                            colorGraph={colorGraph}
                            IS_Retirement_ACC={IS_Retirement_ACC}
                        />
                    </Grid>

                    <Grid container >
                        <Box sx={{ boxShadow: "0px 1px 4px 0px rgba(208, 213, 221, 0.25)", width: "100%", background: "#ffff", border: "1px solid rgba(208, 213, 221, 0.25)", borderRadius: { xs: 0, md: "8px" }, mt: { xs: "24px", md: "40px" } }}>
                            <PortfolioChartCard formik={formik} selectedPorfolio={selectedPorfolio} colorGraph={colorGraph} />
                        </Box>
                    </Grid>

                    <Grid container >
                        <Grid item xs={12} md={12} sx={{ mt: { xs: "24px", md: "40px" }, mb: { xs: "34px", md: "68px" }, borderRadius: "8px", boxShadow: "0px 1px 4px 0px rgba(208, 213, 221, 0.25)" }}>
                            {<Box sx={{ background: "white", p: "8px 16px" }}>
                                {buttonHtml()}
                            </Box>}
                        </Grid>
                    </Grid>

                </Container>
            </Box>

        </>
    )
}

export default Index