/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { LocalUrl } from 'redux/middlewares/api';
import { ACCOUNT_MANAGEMENT_BASE_URL, RISK_PROFILE_BASE_URL, EXCEL_UPLOADER, WEALTH_API_BASE_URL, CASH_FUND_WEALTH_BASE_URL, COMMON_SYNC_CONNECTIONS } from '../../constants';
// import * as AllBankIcon from 'Latest/Images/BankIcon';
import { AllBankIcon } from 'Latest/Images/BankIcon';

const initialState: InitialState = {
  articlesData: [],
  banksList: [],
  cities: [],
  countries: [],
  occupations: [],
  sourcesOfIncome: [],
  holdings: [],
  nationalities: [],
  mobileNetworks: [],
  holdingAllocation: [],
  employee: [],
  country: [],
  countryStates: [],
  // personalizedPortfolio: { holdingsShares: [], name: '', riskScoreFrom: 0, riskScoreTo: 0 }
  personalizedPortfolio: [],
  personalizeScore: -1,
  addGoalBody: {},
  goalList: [],
  personalisedCategory: [],
  cashFundSummary: {},
  performanceGraph: [],
  userBankAcc: [],
  isSideBarOpen: true,
  marketData: [],
  transactionDetail: null,
  investGoalConfig: null,
  investPortfolioAlloc: null,
  notficationHub: {}
};

const name = 'sharedStateData';
const sharedStateDataSlice = createSlice({
  initialState,
  name,
  reducers: {
    countriesSuccess: (state, action: PayloadAction<any>) => {
      const countriesList = action.payload.countries;
      state.countries = countriesList.map((country: any) => ({
        label: country.name,
        value: country.id
      }));
    },
    statesSuccess: (state, action: PayloadAction<any>) => {
      const holdings = action.payload.holdingDtos;
      state.holdings = holdings;
    },
    riskScoreSuccess: (state, action: PayloadAction<any>) => {
      const portfolio = action.payload;
      state.personalizedPortfolio = portfolio;
    },
    citiesSuccess: (state, action: PayloadAction<any>) => {
      const citiesList = action.payload.cities;
      state.cities = citiesList.map((city: any) => ({
        label: city.name,
        value: city.id
      }));
    },
    incomeSourceSuccess: (state, action: PayloadAction<any>) => {
      const sourcesOfIncomesList = action.payload.incomeSources;
      state.sourcesOfIncome = sourcesOfIncomesList.map((source: any) => ({
        label: source.name,
        value: source.id
      }));
    },
    occupationsSuccess: (state, action: PayloadAction<any>) => {
      const occupationsList = action.payload.occupations;
      state.occupations = occupationsList?.map((occupation: any) => ({
        label: occupation.name,
        value: occupation.id
      }));
    },

    employeeData: (state, action: PayloadAction<any>) => {
      const employee = action.payload;
      state.employee = employee;
    },
    countrySuccess: (state, action: PayloadAction<any>) => {
      const country = action.payload;
      state.country = country;
    },
    countryStatesSuccess: (state, action: PayloadAction<any>) => {
      const countryStates = action.payload.states;
      state.countryStates = countryStates;
    },
    banksSuccess: (state, action: PayloadAction<any>) => {
      const banksList = action.payload.banks;
      state.banksList = banksList.map((bank: any) => ({
        label: bank.name,
        value: bank.id,
        image: AllBankIcon[bank.id] || AllBankIcon.DefaultRoundedBankIcon,
      }));
    },
    mobileNetworksSuccess: (state, action: PayloadAction<any>) => {
      const mobileNetworksList = action.payload.mobileNetworks;
      state.mobileNetworks = mobileNetworksList.map((mobileNetwork: any) => ({
        label: mobileNetwork.name,
        value: mobileNetwork.id
      }));
    },
    nationalitiesSuccess: (state, action: PayloadAction<any>) => {
      const nationalitiesList = action.payload.nationalities;
      state.nationalities = nationalitiesList.map((nationality: any) => ({
        label: nationality.name,
        value: nationality.id
      }));
    },
    onGetPersonalisedScoreAction: (state, action: PayloadAction<any>) => {
      state.personalizeScore = action.payload.earnedScore;
    },
    addGoalBodyAction: (state, action: PayloadAction<any>) => {
      state.addGoalBody = action.payload;
    },

    onGetGoalAction: (state, action: PayloadAction<any>) => {
      state.goalList = action.payload;
    },
    onGetPersonalisedCategoryAction: (state, action: PayloadAction<any>) => {
      state.personalisedCategory = action.payload
    },
    onGetCashFundSummaryAction: (state, action: PayloadAction<any>) => {
      state.cashFundSummary = action.payload
    },
    onGetPerformanceGraphAction: (state, action: PayloadAction<any>) => {
      state.performanceGraph = action.payload
    },
    onGetUserBankAccountAction: (state, action: PayloadAction<any>) => {
      state.userBankAcc = action.payload
    },
    onGetTransactionDetailAction: (state, action: PayloadAction<any>) => {
      state.transactionDetail = action.payload
    },
    isSideBarOpenAction: (state, action: PayloadAction<any>) => {
      state.isSideBarOpen = action.payload
    },
    onSuccessArticlesDataAction: (state, action: PayloadAction<any>) => {
      state.articlesData = action.payload
    },
    onSuccessMarketDataAction: (state, action: PayloadAction<any>) => {
      state.marketData = action.payload
    },
    onSuccessInvestGoalConfigAction: (state, action: PayloadAction<any>) => {
      state.investGoalConfig = action.payload
    },
    onSuccessInvestPortfolioAllocAction: (state, action: PayloadAction<any>) => {
      state.investPortfolioAlloc = action.payload
    },
    onSuccessNotificationsDataAction: (state, action: PayloadAction<any>) => {
      state.notficationHub = action.payload
    },
  }
});

export default sharedStateDataSlice.reducer;

export const {
  addGoalBodyAction,
  banksSuccess,
  citiesSuccess,
  countriesSuccess,
  countryStatesSuccess,
  countrySuccess,
  employeeData,
  incomeSourceSuccess,
  isSideBarOpenAction,
  mobileNetworksSuccess,
  nationalitiesSuccess,
  occupationsSuccess,
  onGetCashFundSummaryAction,
  onGetGoalAction,
  onGetPerformanceGraphAction,
  onGetPersonalisedCategoryAction,
  onGetPersonalisedScoreAction,
  onGetTransactionDetailAction,
  onGetUserBankAccountAction,
  onSuccessArticlesDataAction,
  onSuccessInvestGoalConfigAction,
  onSuccessInvestPortfolioAllocAction,
  onSuccessMarketDataAction,
  onSuccessNotificationsDataAction,
  riskScoreSuccess,
  statesSuccess,
} = sharedStateDataSlice.actions;

//? APIS ACTIONS

export const onGetCountries = () => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: countriesSuccess,
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/Country`
  },
  type: 'countries'
});

export const onGetHolding = () => ({
  payload: {
    apiName: 'holdingAllocation',
    method: 'get',
    onSuccess: statesSuccess,
    url: `${RISK_PROFILE_BASE_URL}/api/HoldingAllocation`
  },
  type: 'states'
});

export const onGetPersonalisedScore = (isVps?: any) => {
  let url = `${RISK_PROFILE_BASE_URL}/api/InvestorRiskProfile/score`;
  if (isVps) {
    url += `?accountCategory=VoluntaryPension`;
  }
  return {
    payload: {
      apiName: `personalizedPortfolio`,
      method: 'get',
      onSuccess: onGetPersonalisedScoreAction,
      // onFail,
      url: url
    },
    type: 'states'
  };
}


export const onGetPersonalisedRiskCategory = () => ({
  payload: {
    apiName: 'personalisedRiskCategory',
    method: 'get',
    onSuccess: onGetPersonalisedCategoryAction,
    url: `${WEALTH_API_BASE_URL}/api/RiskCategory`
  },
  type: 'onGetPersonalisedRiskCategory'
});

export const onGetGoal = (userId: string | undefined) => ({
  payload: {
    apiName: 'personalizedPortfolio',
    method: 'get',
    onSuccess: onGetGoalAction,
    url: `${RISK_PROFILE_BASE_URL}/api/InvestorGoal/${userId}`
  },
  type: 'onGetGoal'
});

export const onGetPersonalisedPortfolio = (userId: string | undefined, score: number) => ({
  payload: {
    apiName: 'personalizedPortfolio',
    method: 'get',
    onSuccess: riskScoreSuccess,
    url: `${WEALTH_API_BASE_URL}/api/AssetAllocation/${userId}/details?riskscore=${score}`

  },
  type: 'states'
});

export const onGetAssetAllocation = (userId: string | undefined, FundsType: string, score: number) => ({
  payload: {
    apiName: 'personalizedPortfolio',
    method: 'get',
    onSuccess: riskScoreSuccess,
    url: `${WEALTH_API_BASE_URL}/api/AssetAllocation/${userId}/${FundsType}?riskscore=${score}`
  },
  type: 'states'
});

export const onGetCountry = () => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: countrySuccess,
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/Country`
  },
  type: 'states'
});

export const onGetCountryStates = (country: string) => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: countryStatesSuccess,
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/country/${country}/states`
  },
  type: 'countryStates'
});
export const onGetCities = (state: string) => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: citiesSuccess,
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/state/${state}/cities`
  },
  type: 'cities'
});
export const onGetEmployee = () => ({
  payload: {
    apiName: 'Employee',
    method: 'get',
    onSuccess: employeeData,
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/Employee`
  },
  type: 'cities'
});

export const onFileUpload = (data: any) => ({
  payload: {
    apiName: 'fileUppload',
    data,
    method: 'post',
    // onSuccess: (message: any, response: any) => console.log('response'),
    onFail: (message: any, response: any) =>
      response && toast.error(response.data, { theme: "colored" }),
    url: `${EXCEL_UPLOADER}/api/excel_uploader`
  },
  type: 'fileUppload'
});

export const onGetSourcesOfIncome = () => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: incomeSourceSuccess,
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/incomeSource`
  },
  type: 'incomeSource'
});

export const onPostEmployee = (data: any) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/Employee`
  },
  type: 'Employee'
});
export const onPutEmployee = (data: any) => ({
  payload: {
    apiName: name,
    data,
    method: 'put',
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/Employee`
  },
  type: 'Employee'
});

export const onGetOccupations = () => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: occupationsSuccess,
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/occupation`
  },
  type: 'occupations'
});

export const onGetMobileNetworks = () => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: mobileNetworksSuccess,
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/mobileNetwork`
  },
  type: 'mobileNetworks'
});

export const onGetBanks = () => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: banksSuccess,
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/bank`
  },
  type: 'banks'
});

export const onGetNationalities = () => ({
  payload: {
    apiName: name,
    method: 'get',
    // onSuccess: nationalitiesSuccess,
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/nationalities`
  },
  type: 'nationalities'
});

export const onGetUserBankAccount = () => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: onGetUserBankAccountAction,
    url: `${WEALTH_API_BASE_URL}/api/BankAccount`
  },
  type: 'GetUserBankAcc'
});

export const onPostUserBankAccount = (data: postUserAcc, onSuccess: (message: string, response: any) => void, onFail: (message: string, response: any) => void) => ({
  payload: {
    apiName: name,
    data,
    onSuccess: onSuccess,
    onFail: onFail,
    errorToast: true,
    method: 'post',
    url: `${WEALTH_API_BASE_URL}/api/BankAccount`
  },
  type: 'PostUserBankAcc'
});

export const onDeleteUserBankAccount = (data: postUserAcc, onSuccess: (message: string, response: any) => void, onFail: (message: string, response: any) => void) => ({
  payload: {
    apiName: name,
    data,
    onSuccess: onSuccess,
    onFail: onFail,
    errorToast: true,
    method: 'delete',
    url: `${WEALTH_API_BASE_URL}/api/BankAccount`
  },
  type: 'onDeleteUserBankAccount'
});

export const onGetCashFundSummary = () => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: onGetCashFundSummaryAction,
    url: `${CASH_FUND_WEALTH_BASE_URL}/api/CashFund`
  },
  type: 'cashFundSummary'
});

export const onGetPerformanceGraph = (duration: number) => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: onGetPerformanceGraphAction,
    url: `${CASH_FUND_WEALTH_BASE_URL}/api/CashFund/performance?duration=${duration}`
  },
  type: 'cashFundSummary'
});


export const onGetTransactionDetail = (param: { id: string, category?: string }, onSuccess: (message: string, response: any) => void, onFail: (message: string, response: any) => void) => {
  let url = `${WEALTH_API_BASE_URL}/api/TransactionHistory/${param.id}`;

  if (param.category) {
    url += `/${param.category}`;
  }

  return {
    payload: {
      apiName: name,
      method: 'get',
      onSuccess: onSuccess,
      onFail: onFail,
      url: url
    },
    type: 'onGetTransactionDetail'
  };
};

export const onGetDashboardContributionGraph = (duration: string,
  onSuccess: (message: string, response: any) => void, onFail: (message: string, response: any) => void) => ({
    payload: {
      apiName: name,
      method: 'get',
      onSuccess: onSuccess,
      onFail: onFail,
      url: `${WEALTH_API_BASE_URL}/api/Portfolio/ContributionsGraph?period=${duration}&startDate=${"0001-01-01"}&endDate=${"0001-03-20"}`
    },
    type: 'onGetDashboardContributionGraph'
  });
export const onGetDashboardAccGraph = (duration: string,
  onSuccess: (message: string, response: any) => void, onFail: (message: string, response: any) => void) => ({
    payload: {
      apiName: name,
      method: 'get',
      onSuccess: onSuccess,
      onFail: onFail,
      url: `${WEALTH_API_BASE_URL}/api/Portfolio/AccountsGraph?period=${duration}&startDate=${"0001-01-01"}&endDate=${"0001-03-20"}`
    },
    type: 'onGetDashboardAccGraph'
  });

export const onGetMarketData = () => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: onSuccessMarketDataAction,
    // onFail,
    url: `${WEALTH_API_BASE_URL}/api/MarketData`
  },
  type: 'onGetMarketData'
});

export const onGetArticlesData = () => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: onSuccessArticlesDataAction,
    // onFail,
    url: `${WEALTH_API_BASE_URL}/api/WebScraper/WebflowData?timeZoneHours=5`
  },
  type: 'onGetArticlesData'
})

export const onGetInvestGoalConfig = (isVps?: any) => {
  let url = `${WEALTH_API_BASE_URL}/api/InvestGoal/config`;
  if (isVps) {
    url += `?accountCategory=VoluntaryPension`;
  } else{
    url += `?accountCategory=GeneralInvestment`;
  }
  return {
    payload: {
      apiName: name,
      method: 'get',
      onSuccess: onSuccessInvestGoalConfigAction,
      // onFail,
      url: url
    },
    type: 'onGetInvestGoalConfig'
  };
}

export const createInvestGoal = (data: any, onSuccess: (message: string, response: any) => void) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    onSuccess: onSuccess,
    url: `${WEALTH_API_BASE_URL}/api/InvestGoal`,
  },
  type: 'createInvestGoal'
});

export const createInvestGoalPut = (data: any, id: string, onSuccess: (message: string, response: any) => void) => ({
  payload: {
    apiName: name,
    data,
    method: 'put',
    onSuccess,
    url: `${WEALTH_API_BASE_URL}/api/InvestGoal/${id}`,
  },
  type: 'createInvestGoalPut'
});

export const onGetInvestPortfolioAllocation = (isVps?: any) => {
  let url = `${WEALTH_API_BASE_URL}/api/InvestPortfolioAllocation`;
  if (isVps) {
    url += `?accountCategory=VoluntaryPension`;
  } else {
    url += `?accountCategory=GeneralInvestment`;
  }
  return {
    payload: {
      apiName: name,
      method: 'get',
      onSuccess: onSuccessInvestPortfolioAllocAction,
      // onFail,
      url: url
    },
    type: 'onGetInvestPortfolioAllocation'
  };
}
export const onGetInvestProductsValue = (name: string, amt: string | number, id: string, onSuccess: (message: string, response: any) => void) => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess,
    errorToast: true,
    // onFail,
    url: `${WEALTH_API_BASE_URL}/api/InvestGoal/productsvalue/${id}?totalAmount=${amt}`
  },
  type: 'onGetInvestProductsValue'
});

export const onGetNotifications = () => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: onSuccessNotificationsDataAction,
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/Notifications`
  },
  type: 'onGetNotifications'
});

export const onPatchEditGoal = (data: any, id: any, onSuccess: any) => ({
  payload: {
    apiName: name,
    data,
    // formData: true,
    onSuccess,
    method: 'patch',
    url: `${WEALTH_API_BASE_URL}/api/InvestGoal/${id}/update`
  },
  type: 'onPatchEditGoal'
});

export const onDeleteGoal = (data: any, id: any, onSuccess: any) => ({
  payload: {
    apiName: name,
    data,
    // formData: true,
    onSuccess,
    method: 'delete',
    url: `${WEALTH_API_BASE_URL}/api/InvestGoal/${id}/delete`
  },
  type: 'onDeleteGoal'
});

export const onPatchNotifications = (data: any) => ({
  payload: {
    apiName: name,
    data,
    formData: true,
    method: 'patch',
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/Notifications`
  },
  type: 'onPatchNotifications'
});

export const onPatchDividend = (data: any, onSuccess: (message: string, response: any) => void) => ({
  payload: {
    apiName: name,
    data,
    method: 'post',
    onSuccess,
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/UserAccount/declare/dividend`
  },
  type: 'onPatchDividend'
});



export const onPatchSubmitKYCStatus = () => ({
  payload: {
    apiName: name,
    formData: true,
    method: 'patch',
    url: `${ACCOUNT_MANAGEMENT_BASE_URL}/api/UserDocument/kyc/secondtier/status?status=4`
  },
  type: 'onPatchSubmitKYCStatus'
});

export const onGetCFPortfolioSummmary = (data: any, onSuccess: (message: string, response: any) => void, onFail: (message: string, response: any) => void) => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess,
    onFail,
    url: `${CASH_FUND_WEALTH_BASE_URL}/api/CashFund/${data.id}/fundsummary?portfolioAmount=${data.amt}`
  },
  type: 'onGetCFPortfolioSummmary'
});

export const onGetGIPortfolioSummmary = (data: any, onSuccess: (message: string, response: any) => void, onFail: (message: string, response: any) => void) => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess,
    onFail,
    url: `${WEALTH_API_BASE_URL}/api/InvestGoal/${data.id}/fundsummary?portfolioAmount=${data.amt}`
  },
  type: 'onGetGIPortfolioSummmary'
});

export const onGetCFPerformanceGraph = (payload: any, onSuccess: (message: string, response: any) => void, onFail: (message: string, response: any) => void) => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: onSuccess,
    onFail: onFail,
    url: `${WEALTH_API_BASE_URL}/api/CashFundAccount/${payload.goalId}/performancegraph?period=${payload.duration}`
  },
  type: 'onGetCFPerformanceGraph'
});

export const onGetGIPerformanceGraph = (payload: any, onSuccess: (message: string, response: any) => void, onFail: (message: string, response: any) => void) => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: onSuccess,
    onFail: onFail,
    url: `${WEALTH_API_BASE_URL}/api/InvestGoal/${payload.goalId}/performancegraph?period=${payload.duration}`
  },
  type: 'onGetGIPerformanceGraph'
});

export const onGetAdvisors = (onSuccess: (message: any, response: any) => void) => ({
  payload: {
    apiName: name,
    method: 'get',
    onSuccess: onSuccess,
    url: `${WEALTH_API_BASE_URL}/api/booking/adviser`
  },
  type: 'onGetAdvisors'
});
export const onRequestASlot = (data: any, onSuccess: (message: any, response: any) => void) => ({
  payload: {
    apiName: "RequestSlot",
    data,
    method: 'post',
    onSuccess: onSuccess,
    url: `${WEALTH_API_BASE_URL}/api/booking/adviser/Email`
  },
  type: 'onRequestASlot'
});

// ? Types

export interface Country {
  id: number;
  name: string;
  phone_code: string;
  currency: string;
  label: string;
  value: string;
}
interface CountryPayload {
  countries: Array<SpecificCountry>;
}
interface SpecificCountry {
  id: string;
  name: string;
}
interface CityPayload {
  Data: string;
}
interface OccupationPayload {
  Data: string;
}
// type OccupationPayload = Array<Omit<Occupation, 'label' | 'value'>>;
// type sourcesOfIncomePayload = Array<Omit<SourcesOfIncome, 'label' | 'value'>>;

interface sourcesOfIncomePayload {
  Data: string;
}
interface StatePayload {
  Data: string;
}
export interface State {
  id: number;
  name: string;
  StateCode: string;
  label: string;
  value: string;
}
export interface SourcesOfIncome {
  id: number;
  name: string;
  label: string;
  value: string;
}
export interface Occupation {
  id: number;
  name: string;
  id1: number;
  label: string;
  value: string;
}
export interface City {
  id: number;
  name: string;
  label: string;
  value: string;
}

export interface sourceOfIncomeProps {
  id: number;
  name: string;
}

export interface occupationProps {
  id: number;
  name: string;
  id1: number;
}

export interface banksProps {
  label: string;
  value: string;
}

export interface banksProps {
  id: string;
  name: string;
}
export interface keyValueProps {
  key: string | number;
  value: number;
}

export interface mobileNetworkProps {
  id: string;
  name: string;
}

export interface nationalityProps {
  id: string;
  name: string;
}

export interface HoldingsShare {
  name: string;
  percentage: number;
  portfolioAllocations: string[];
  amount: number;
}

export interface PersonalizedPortfolio {
  name: string;
  riskScoreFrom: number;
  riskScoreTo: number;
  holdingsShares: HoldingsShare[];
}

export interface postUserAcc {
  accountTitle?: string,
  accountNumber: string,
  bankName: string
}
interface InitialState {
  articlesData: any;
  banksList: Array<banksProps>;
  employee: Array<any>;
  country: Array<any>;
  countryStates: Array<any>;
  countries: Array<Country>;
  cities: Array<City>;
  holdings: Array<State>;
  sourcesOfIncome: Array<SourcesOfIncome>;
  occupations: Array<Occupation>;
  nationalities: Array<nationalityProps>;
  mobileNetworks: Array<mobileNetworkProps>;
  holdingAllocation: any;
  // personalizedPortfolio: PersonalizedPortfolio;
  personalizedPortfolio: any;
  personalizeScore: number;
  addGoalBody: any;
  goalList: any;
  personalisedCategory: any;
  cashFundSummary: any;
  userBankAcc: any,
  transactionDetail: any,
  performanceGraph: any,
  isSideBarOpen: boolean,
  marketData: any,
  investGoalConfig: any,
  investPortfolioAlloc: any,
  notficationHub: any,
}
